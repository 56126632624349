import { NavItem } from "react-bootstrap";

const Qualification = (props) => {
    const qualInfo = props.qualInfo;
    return (
        <div className="_dashboard_content_body py-3 px-3">
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <div className="form-group">
                        <label className="text-dark ft-medium">Qualification</label>
                        <input type="text" name="qualification" className="form-control rounded" placeholder="Qualification" onChange={e => props.handleInputChange(e, props._index)} defaultValue={qualInfo.qualification || ""} />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <div className="form-group">
                        <label className="text-dark ft-medium">University / College</label>
                        <input type="text" name="university" className="form-control rounded" placeholder="College" onChange={e => props.handleInputChange(e, props._index)} defaultValue={qualInfo.university || ""} />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <div className="form-group">
                        <label className="text-dark ft-medium">Passing Year</label>
                        <input type="text" name="passing_year" className="form-control rounded" placeholder="Passing Year" onChange={e => props.handleInputChange(e, props._index)} defaultValue={qualInfo.passing_year || ""} />
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <div className="form-group">
                        <label className="text-dark ft-medium">Marks Obtained</label>
                        <input type="text" name="marks_obtained" className="form-control rounded" placeholder="mark" onChange={e => props.handleInputChange(e, props._index)} defaultValue={qualInfo.marks_obtained || ""} />
                    </div>
                </div>
                
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                        <label className="text-dark ft-medium">About Qualification</label>
                        <textarea type="text" name="about_qualification" className="form-control rounded" placeholder="About Qualification" onChange={e => props.handleInputChange(e, props._index)} defaultValue={qualInfo?.about_qualification || ''}></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
            <div className="col-xl-1 col-lg-1">
                    <div className="form-group">
                        <button type="button" className="btn btn-md ft-medium text-light rounded theme-bg" onClick={() => props.addMore()}>Add</button>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-2">
                    <div className="form-group">
                        <button type="button" className="btn btn-md ft-medium text-light rounded theme-bg" onClick={() => props.removeQualification(qualInfo._id)}>Remove</button>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default Qualification;