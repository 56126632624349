import { SEARCH } from "../action/types";

export default function (state = {}, action) {
    switch (action.type) {
        case SEARCH:
            return { ...state, searchedResult: JSON.parse(action.payload) }
        default:
            return state;
    }
}
