import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getLocationByPinOrCity, notify } from "../../model/common";
import { getUser, updateProfile } from "../../model/user";
import DashboardNavigation from "../Include/dashboardnavigation";
import InnerHeader from "../Include/Header/innerheader";
import LoginModal from "../Popup/loginmodal";
import OfferCourses from "./offerCourses";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import  ClassicEditor  from "@ckeditor/ckeditor5-build-classic";
import Toast from "react-bootstrap/Toast";
import axios from "axios";
import { BASE_URL } from "../../action/types";
import { getAuthToken } from "../../Services/util";
import { IMAGE_UPLOAD_SUCCESS, NOTIFY_ACTION_SUCCESS } from "../../config/constant";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Profile = (props) => {

	let [selectedFile, setSelectedFile] = useState(null);
	let [genderDefaultValue, setGenderDefaultvalue] = useState('');
	let [genderMD, setGenderMD] = useState([]);
	let [coursesMD, setCoursesMD] = useState([]);
	let [teachingModeMD, setTeachingModeMD] = useState([]);
	let [feeTypeMD, setFeeTypeMD] = useState([]);
	let [locations, setLocations] = useState([]);
	let [offerCoursesValue, setOfferCoursesValue] = useState([
		{ _id: new Date().getTime() + Math.random(), course_id: null, teaching_mode: null, fee_type: null, fee: '', about_course: '', experience: 0 }
	]);
	let [defaultOfferedCourseOpen, setDefaultOfferedCourseOpen] = useState(true);
	let [userProfile, setUserProfile] = useState({
		first_name: '',
		last_name: '',
		email: '',
		gender: '',
		user_type: '',
		experience: '',
		age: '',
		about_me: '',
		language: '',
		higher_education: '',
		phone_number: '',
		course_id: 0,
		courses: {},
		facebook: '',
		twitter: '',
		linkedin: '',
		pincode: '',
		city: '',
		state: '',
		address: '',
		latitude: '',
		longitude: '',
		userCourses: []
	});

	const onFileChange = (e) => {
		onFileUpload(e.target.files[0]);
	}

	const onFileUpload = (file) => {
		// Create an object of formData
		const formData = new FormData();
     
		// Update the formData object
		formData.append(
		  "file",
		  file,
		  file.name
		);
	   
		// Details of the uploaded file
	   
		// Request made to the backend api
		// Send formData object
		const headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+ getAuthToken()
		  }
		axios.post(BASE_URL+"api/admin/user/profile/asset", formData, {headers})
		.then((result) => {
			notify(NOTIFY_ACTION_SUCCESS, IMAGE_UPLOAD_SUCCESS);
		}).catch((err) => {
			console.log('File couldn\'t uploaded');
			console.log(err);
		})
		
	}
	useEffect(() => {
		if (props.commonData.data) {
			let genderData = props.commonData.data.masterData.data.filter(item => item.attribute_name === 'gender');
			setGenderMD(genderData);
			let teachingModeData = props.commonData.data.masterData.data.filter(item => item.attribute_name === 'teaching_mode');
			setTeachingModeMD(teachingModeData);
			let feeTypeData = props.commonData.data.masterData.data.filter(item => item.attribute_name === 'fee_type');
			setFeeTypeMD(feeTypeData);
			setCoursesMD(props.commonData.data.courses.data);

			if (!locations.length) {
				// setLocations(props.commonData.data.locations.data);
			}
		}
	}, [props.commonData]);


	useEffect(() => {
		props.getUser();
	}, []);

	useEffect(() => {
		props.userProfileData.data !== undefined && setUserProfile(props.userProfileData.data);
		// If user has courses set then make the courses section close by default
		userProfile.userCourses.length > 0 && setDefaultOfferedCourseOpen(false);
		if(props.userProfileData?.status) {
			notify(NOTIFY_ACTION_SUCCESS, props.userProfileData?.message);
		}
	}, [props.userProfileData]);

	useEffect(() => {
		let locationInfo = props.locationData && props.locationData.data && [...props.locationData.data].shift();
		locationInfo && setUserProfile(values => ({ ...values, pincode: locationInfo.pincode, city: locationInfo.city_name, state: locationInfo.state_name }));
	},[props.locationData]);
	const isSelected = (event) => {
		if (event.target.value === 'female') {
			setGenderDefaultvalue(event.target.value);
		}
	}

	const handleAddMoreCourse = (e) => {
		e.preventDefault();
		setDefaultOfferedCourseOpen(true);
		setOfferCoursesValue([...offerCoursesValue, { _id: new Date().getTime() + Math.random(), course_id: null, teaching_mode: null, fee_type: null, fee: '', about_course: '', experience: 0 }]);
	}

	const handleRemoveOfferCourse = (removeId) => {
		let offerCoursesValueNew = [...offerCoursesValue];
		offerCoursesValueNew.map((item, index) => {
			if (item._id === removeId) {
				offerCoursesValueNew.splice(index, 1);
			}
		});
		setOfferCoursesValue(offerCoursesValueNew);
	}

	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...offerCoursesValue];
		list[index][name] = value;
		setOfferCoursesValue(list);
	};

	const handleProfileUpdate = (e) => {
		e.preventDefault();
		setUserProfile({ ...userProfile, courses: offerCoursesValue });

		props.updateProfile({ ...userProfile, courses: offerCoursesValue });
	}

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setUserProfile(values => ({ ...values, [name]: value }))
	}

	const handleLookupPincode = async (event) => {
		const value = event.target.value;
		await props.getLocationByPinOrCity(value);
		// handleChange(event);
		// let locationInfo = locations.find((item) => {
		// 	return item.pincode===value;
		// });
		// if(locationInfo) {
		// 	setUserProfile(values => ({...values, city: locationInfo.city_name, state: locationInfo.state_name}));
		// }
	}

	const handleLookupCity = async (event) => {
		const value = event.target.value;
		await props.getLocationByPinOrCity(value);

		setUserProfile(values => ({ ...values, city: props.locationData.data.city_name, state: props.locationData.data.state_name }));
		// handleChange(event);
		// let locationInfo = locations.find((item) => {
		// 	return item.city_name===value;
		// });
		// if(locationInfo) {
		// 	setUserProfile(values => ({...values, city: locationInfo.city_name, state: locationInfo.state_name}));
		// }
	}

	useEffect(() => {
		props.configureHeader("header header-light dark-text");
	}, []);

	return (
		<>
			<ToastContainer />
			{/* <InnerHeader {...props}></InnerHeader> */}

			<div className="dashboard-wrap bg-light">
				<DashboardNavigation></DashboardNavigation>

				<div className="dashboard-content">
					<div className="dashboard-tlbar d-block mb-5">
						<div className="row">
							<div className="colxl-12 col-lg-12 col-md-12">
								<h1 className="ft-medium">My Profile</h1>
								<nav aria-label="breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item text-muted"><a href="#">Home</a></li>
										<li className="breadcrumb-item text-muted"><a href="#">Dashboard</a></li>
										<li className="breadcrumb-item"><a href="#" className="theme-cl">My Profile</a></li>
									</ol>
								</nav>
							</div>
						</div>
					</div>

					<div className="dashboard-widg-bar d-block">
						<form onSubmit={handleProfileUpdate}>
							<div className="row">
								<div className="col-xl-12 col-lg-12 col-md-12">
									<div className="_dashboard_content bg-white rounded mb-4">
										<div className="_dashboard_content_header br-bottom py-3 px-3">
											<div className="_dashboard__header_flex">
												<h4 className="mb-0 ft-medium fs-md"><i className="fa fa-user mr-1 theme-cl fs-sm"></i>My Account</h4>
											</div>
										</div>

										<div className="_dashboard_content_body py-3 px-3">
											<div className="row">
												<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
													<div className="custom-file avater_uploads">
														<input type="file" className="custom-file-inpu" id="customFile" onChange={onFileChange} defaultValue={userProfile.profileImage !== undefined ? userProfile.profileImage : ''} />
														<label className="custom-file-label" for="customFile"><i className="fa fa-user"></i></label>
													</div>
												</div>

												<div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
													<div className="row">
														<div className="col-xl-6 col-lg-6">
															<div className="form-group">
																<label className="text-dark ft-medium">First Name</label>
																<input type="text" className="form-control rounded" placeholder="First Name" name="first_name" value={userProfile.first_name || ""} onChange={handleChange} />
															</div>
														</div>
														<div className="col-xl-6 col-lg-6">
															<div className="form-group">
																<label className="text-dark ft-medium">Last Name</label>
																<input type="text" className="form-control rounded" placeholder="Job Name" name="last_name" value={userProfile.last_name || ""} onChange={handleChange} />
															</div>
														</div>
														<div className="col-xl-4 col-lg-4">
															<div className="form-group">
																<label className="text-dark ft-medium">Phone</label>
																<input type="text" className="form-control rounded" placeholder="Phone" name="phone_number" value={userProfile.phone_number || ""} onChange={handleChange} />
															</div>
														</div>
														<div className="col-xl-4 col-lg-4">
															<div className="form-group">
																<label className="text-dark ft-medium">Email</label>
																<input type="email" className="form-control rounded" placeholder="email" name="email" value={userProfile.email} onChange={handleChange} />
															</div>
														</div>
														<div className="col-xl-4 col-lg-4">
															<div className="form-group">
																<label className="text-dark ft-medium">Gender</label>
																<select className="custom-select rounded" value={genderDefaultValue} name="gender" onChange={handleChange}>
																	<option disabled>Select Gender</option>
																	{
																		genderMD && genderMD.map(item => <option key={item.master_key} value={item.master_key}>{item.master_value}</option>)
																	}
																</select>
															</div>
														</div>

														<div className="col-xl-6 col-lg-6">
															<div className="form-group">
																<label className="text-dark ft-medium">Experience (Years)</label>
																<input type="text" className="form-control rounded" placeholder="Experience" name="experience" value={userProfile.experience} onChange={handleChange} />
															</div>
														</div>
														<div className="col-xl-6 col-lg-6">
															<div className="form-group">
																<label className="text-dark ft-medium">Higher Education</label>
																<input type="text" className="form-control rounded" placeholder="Education" name="higher_education" value={userProfile.higher_education || ""} onChange={handleChange} />
															</div>
														</div>
														<div className="col-xl-6 col-lg-6">
															<div className="form-group">
																<label className="text-dark ft-medium">Age</label>
																<input type="text" className="form-control rounded" placeholder="Age" name="age" value={userProfile.age} onChange={handleChange} />
															</div>
														</div>
														<div className="col-xl-6 col-lg-6">
															<div className="form-group">
																<label className="text-dark ft-medium">Language</label>
																<input type="text" className="form-control rounded" placeholder="e.x Englisg, Hindi" name="language" value={userProfile.language} onChange={handleChange} />
															</div>
														</div>
														<div className="col-xl-12 col-lg-12">
															<div className="form-group">
																<label className="text-dark ft-medium">About Info</label>
																{/* <textarea className="form-control with-light" name="about_me" defaultValue={userProfile.about_me} onChange={handleChange}></textarea> */}
																<CKEditor
                    editor={ ClassicEditor }
                    data={userProfile.about_me}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        // console.log( { event, editor, data } );
						setUserProfile(values => ({...values, about_me: data}));
                    } }
                    onBlur={ ( event, editor ) => {
                        // console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        // console.log( 'Focus.', editor );
                    } }
                />
															</div>
														</div>

														<div className="col-xl-12 col-lg-12">
															<div className="form-group">
																<button type="submit" className="btn btn-md ft-medium text-light rounded theme-bg">Save Changes</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								{/* <div className="col-lg-12 col-md-12">
									<div className="_dashboard_content bg-white rounded mb-4">
										<div className="_dashboard_content_header br-bottom py-3 px-3">
											<div className="_dashboard__header_flex">
												<h4 className="mb-0 ft-medium fs-md"><i className="ti-book mr-1 theme-cl fs-sm"></i>Offered Courses</h4>
												<div className="br-right">
													<a href="#" onClick={handleAddMoreCourse} className="ft-medium">
														<i className="ti-plus mr-1 theme-cl fs-sm"></i>Add More Course
													</a>
												</div>
											</div>
										</div>
										{
											userProfile && userProfile.userCourses && userProfile.userCourses.map((item, index) => <OfferCourses
											key={index} {...props}
											_index={index}
											courses={coursesMD}
											teaching_mode={teachingModeMD}
											handleInputChange={handleInputChange}
											fee_type={feeTypeMD}
											id={item._id}
											removeOfferCourseValue={handleRemoveOfferCourse}
											userProfile={userProfile}
											handleChange={handleChange}
											offeredCourse={item}
											>

										</OfferCourses>)
										}
										{
											defaultOfferedCourseOpen && offerCoursesValue.map((item, index) => <OfferCourses
												key={index} {...props}
												_index={index}
												courses={coursesMD}
												teaching_mode={teachingModeMD}
												handleInputChange={handleInputChange}
												fee_type={feeTypeMD}
												id={item._id}
												removeOfferCourseValue={handleRemoveOfferCourse}
												userProfile={userProfile}
												handleChange={handleChange}>

											</OfferCourses>)
										}

									</div>
								</div> */}

								<div className="col-lg-12 col-md-12">
									<div className="_dashboard_content bg-white rounded mb-4">
										<div className="_dashboard_content_header br-bottom py-3 px-3">
											<div className="_dashboard__header_flex">
												<h4 className="mb-0 ft-medium fs-md"><i className="ti-facebook mr-1 theme-cl fs-sm"></i>Social Accounts</h4>
											</div>
										</div>

										<div className="_dashboard_content_body py-3 px-3">
											<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
												<div className="form-group">
													<label className="text-dark ft-medium">Facebook</label>
													<input type="text" className="form-control rounded" placeholder="https://www.facebook.com/" name="facebook" value={userProfile.facebook || ""} onChange={handleChange} />
												</div>
											</div>
											<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
												<div className="form-group">
													<label className="text-dark ft-medium">Twitter</label>
													<input type="text" className="form-control rounded" placeholder="https://www.twitter.com/" name="twitter" value={userProfile.twitter || ""} onChange={handleChange} />
												</div>
											</div>
											<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
												<div className="form-group">
													<label className="text-dark ft-medium">LinkedIn</label>
													<input type="text" className="form-control rounded" placeholder="https://www.linkedin.com/" name="linkedin" value={userProfile.linkedin || ""} onChange={handleChange} />
												</div>
											</div>
											<div className="col-xl-12 col-lg-12">
												<div className="form-group">
													<button type="submit" className="btn btn-md ft-medium text-light rounded theme-bg">Save Changes</button>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-12 col-md-12">
									<div className="_dashboard_content bg-white rounded mb-4">
										<div className="_dashboard_content_header br-bottom py-3 px-3">
											<div className="_dashboard__header_flex">
												<h4 className="mb-0 ft-medium fs-md"><i className="fas fa-lock mr-1 theme-cl fs-sm"></i>Contact Information</h4>
											</div>
										</div>

										<div className="_dashboard_content_body py-3 px-3">
											<div className="row">
												<div className="col-xl-4 col-lg-4 col-md-12">
													<div className="form-group">
														<label className="text-dark ft-medium">Pincode</label>
														<input type="text" className="form-control rounded" placeholder="Pincode" name="pincode" value={userProfile.pincode || ""} onChange={handleChange} onBlur={handleLookupPincode} />
													</div>
												</div>
												<div className="col-xl-4 col-lg-4 col-md-12">
													<div className="form-group">
														<label className="text-dark ft-medium">City</label>
														<input type="text" className="form-control rounded" placeholder="City" name="city" value={userProfile.city || ""} onChange={handleChange} onBlur={handleLookupPincode} />
													</div>
												</div>
												<div className="col-xl-4 col-lg-4 col-md-12">
													<div className="form-group">
														<label className="text-dark ft-medium">State</label>
														<input type="text" className="form-control rounded" placeholder="State" name="state" value={userProfile.state || ""} />
													</div>
												</div>
												<div className="col-xl-12 col-lg-12 col-md-12">
													<div className="form-group">
														<label className="text-dark ft-medium">Full Address</label>
														<input type="text" className="form-control rounded" placeholder="Address" name="address" value={userProfile.address || ""} onChange={handleChange} />
													</div>
												</div>
												<div className="col-xl-6 col-lg-6 col-md-12">
													<div className="form-group">
														<label className="text-dark ft-medium">Latitude</label>
														<input type="text" className="form-control rounded" placeholder="Laitutde" name="latitude" value={userProfile.latitude || ""} onChange={handleChange} />
													</div>
												</div>
												<div className="col-xl-6 col-lg-6 col-md-12">
													<div className="form-group">
														<label className="text-dark ft-medium">Longitude</label>
														<input type="text" className="form-control rounded" placeholder="Longitude" name="longitude" value={userProfile.longitude || ""} onChange={handleChange} />
													</div>
												</div>
												<div className="col-xl-12 col-lg-12">
													<div className="form-group">
														<button type="submit" className="btn btn-md ft-medium text-light rounded theme-bg">Save Changes</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
						{/* <!-- End Form Here--> */}
					</div>

					{/* <!-- footer --> */}
					<div className="row">
						<div className="col-md-12">
							<div className="py-3">© 2022 Workplex. Designd By ThemezHub.</div>
						</div>
					</div>

				</div>

			</div>

			{/* <LoginModal {...props}></LoginModal> */}
		</>
	)
}

const mapStateToProps = state => {
	return {
		userProfileData: state.userReducer.userProfileData ? state.userReducer.userProfileData : [],
		locationData: state.commonReducer.locdata && state.commonReducer.locdata.data ? state.commonReducer.locdata.data : []
	}
}

export default connect(mapStateToProps, { getUser, updateProfile, getLocationByPinOrCity })(Profile);