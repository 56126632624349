const TopCandidates = () => {
    return(
        <section className="space min">
            <div className="container">
            
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="sec_title position-relative text-center mb-5">
                            <h6 className="text-muted mb-0">Top Candidates</h6>
                            <h2 className="ft-bold">Hire Top <span className="theme-cl">Candidates</span></h2>
                        </div>
                    </div>
                </div>
                
                
                <div className="row align-items-center">
                
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="job_grid_thumbmb-2 text-center pt-4 px-3 mb-2">
                                <a href="candidate-detail.html" className="d-inline-flex text-center m-auto circle border p-2"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="candidate-detail.html" className="text-dark fs-md">John K. Surber</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <div className="df-1 text-dark ft-medium col-12 mt-3"><a href="candidate-detail.html" className="btn gray apply-btn rounded full-width">View Candidate<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="job_grid_thumbmb-2 text-center pt-4 px-3 mb-2">
                                <a href="candidate-detail.html" className="d-inline-flex text-center m-auto circle border p-2"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="candidate-detail.html" className="text-dark fs-md">Loretta E. Reyes</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <div className="df-1 text-dark ft-medium col-12 mt-3"><a href="candidate-detail.html" className="btn gray apply-btn rounded full-width">View Candidate<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="job_grid_thumbmb-2 text-center pt-4 px-3 mb-2">
                                <a href="candidate-detail.html" className="d-inline-flex text-center m-auto circle border p-2"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="candidate-detail.html" className="text-dark fs-md">Adam B. Johnston</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <div className="df-1 text-dark ft-medium col-12 mt-3"><a href="candidate-detail.html" className="btn gray apply-btn rounded full-width">View Candidate<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="job_grid_thumbmb-2 text-center pt-4 px-3 mb-2">
                                <a href="candidate-detail.html" className="d-inline-flex text-center m-auto circle border p-2"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="candidate-detail.html" className="text-dark fs-md">Jessica G. Leininger</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <div className="df-1 text-dark ft-medium col-12 mt-3"><a href="candidate-detail.html" className="btn gray apply-btn rounded full-width">View Candidate<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="job_grid_thumbmb-2 text-center pt-4 px-3 mb-2">
                                <a href="candidate-detail.html" className="d-inline-flex text-center m-auto circle border p-2"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="candidate-detail.html" className="text-dark fs-md">Charles T. Gerena</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <div className="df-1 text-dark ft-medium col-12 mt-3"><a href="candidate-detail.html" className="btn gray apply-btn rounded full-width">View Candidate<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                   
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="job_grid_thumbmb-2 text-center pt-4 px-3 mb-2">
                                <a href="candidate-detail.html" className="d-inline-flex text-center m-auto circle border p-2"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="candidate-detail.html" className="text-dark fs-md">Julie D. Morales</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <div className="df-1 text-dark ft-medium col-12 mt-3"><a href="candidate-detail.html" className="btn gray apply-btn rounded full-width">View Candidate<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                   
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="job_grid_thumbmb-2 text-center pt-4 px-3 mb-2">
                                <a href="candidate-detail.html" className="d-inline-flex text-center m-auto circle border p-2"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="candidate-detail.html" className="text-dark fs-md">Samantha D. Hebert</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <div className="df-1 text-dark ft-medium col-12 mt-3"><a href="candidate-detail.html" className="btn gray apply-btn rounded full-width">View Candidate<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="job_grid_thumbmb-2 text-center pt-4 px-3 mb-2">
                                <a href="candidate-detail.html" className="d-inline-flex text-center m-auto circle border p-2"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="candidate-detail.html" className="text-dark fs-md">Patricia B. North</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <div className="df-1 text-dark ft-medium col-12 mt-3"><a href="candidate-detail.html" className="btn gray apply-btn rounded full-width">View Candidate<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </section>
    )
}

export default TopCandidates;