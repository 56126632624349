import axios from "axios";
import { BASE_URL, SEARCH } from "../action/types";

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export const search = (value, history) => async dispatch => {
    const headers = {
        'Content-Type': 'application/json',
    }
    axios.post(BASE_URL + 'api/admin/search', value , { headers: headers })
        .then((result) => {
            dispatch({ type: SEARCH, payload: JSON.stringify(result) });
        })
        .catch((err) => {
            dispatch({ type: SEARCH, payload: null });
        });
}