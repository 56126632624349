const UserCourses = (props) => {
    return (
        <div className="abt-cdt d-block full-width mb-4">
            <h4 className="ft-medium mb-1 fs-md">About Courses & Experience</h4>
            <div className="exslio-list mt-3">
                <ul>
                    {props?.courses?.map((item) =>
                        <li>
                            <div className="esclio-110 bg-light rounded px-3 py-3">
                                <h4 className="mb-0 ft-medium fs-md">{ item.course }</h4>
                                <div className="esclio-110-info full-width mb-2">
                                    <span className="text-muted mr-2"><i className="lni lni-map-marker mr-1"></i>{ item.teaching_mode }</span>
                                    <span className="text-muted mr-2"><i className="lni lni-laptop-phone mr-1"></i>{ item.fee_type +', '+ item.fee }</span>
                                    <span className="text-muted mr-2"><i className="lni lni-calendar mr-1"></i>{ item.experience }</span>
                                </div>
                                <div className="esclio-110-decs full-width">
                                    { item.about_course }
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default UserCourses;