import { useEffect, useRef, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import JobNotification from "../Home/jobnotification";
import BredCrumbs from "../Include/bredcrumbs";
import Footer from "../Include/Header/footer";
import InnerHeader from "../Include/Header/innerheader";
import { useSelector, useDispatch, connect } from "react-redux";
import { addUser } from "../../model/user";
import { isLogin } from "../../Services/util";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";

const SignUp = (props) => {
    const [loginErrFlag, setLoginErrFlag] = useState(false);
    const navigate = useNavigate();
    const first_name = useRef('');
    const last_name = useRef('');
    const email = useRef('');
    const user_type = useRef('');
    const password = useRef('');
    const confirm_password = useRef('');
    const newsletter = useRef('');
    const crsf_token = useRef('');
    const [userType, setUserType] = useState([]);
    const [token, setToken] = useState('');
    useEffect(() => {
        let userTypeMd = props.commonData.data && props.commonData.data.roles.data.map(item => item);
        setUserType(userTypeMd);
        props.commonData && props.commonData.data && setToken(props.commonData.data.token);
    }, [props.commonData, props.result]);

    const handleAddUser = async (e) => {
        e.preventDefault();
        const newUser = {
            first_name: first_name.current.value,
            last_name: last_name.current.value,
            email: email.current.value,
            user_type: user_type.current.value,
            password: password.current.value,
            newsletter: document.getElementById(newsletter.current.id).checked ? 1 : 0,
            // _token: crsf_token.current.value
        }
        await props.addUser(newUser);
        navigate("/profile", { replace: true });
    }

    useEffect(()=> {
		props.configureHeader("header header-light dark-text");
	  },[]);

      useEffect(() => {
        if(props.loginResponse && props.loginResponse.code == 500) {
            setLoginErrFlag(true);
        }
    },[props.loginResponse]);

    useEffect(() => {
        setTimeout(() => {
            setLoginErrFlag(false);
        }, 5000);
    },[loginErrFlag]);
    return (
        <>

            {/* <!-- ============================================================== -->
        <!-- Top header  -->
        <!-- ============================================================== -->
        <!-- Start Navigation --> */}
            {/* <InnerHeader {...props}></InnerHeader> */}
            {/* <!-- End Navigation --> */}

            {/* <!-- ============================================================== -->
        <!-- Top header  -->
        <!-- ============================================================== -->
        
        <!-- ======================= Top Breadcrubms ======================== --> */}
            <BredCrumbs></BredCrumbs>
            {/* <!-- ======================= Top Breadcrubms ======================== -->
        
        <!-- ======================= Login Detail ======================== --> */}
            <section className="middle">
                <div className="container">
                    <div className="row align-items-start justify-content-between">

                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <Alert key={"warning"} variant={"warning"} onClose={() => setLoginErrFlag(false)} show={loginErrFlag || false} dismissible>
                            { loginErrFlag && props.loginResponse.message}
                        </Alert>
                            <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
                            <form className="border p-3 rounded" onSubmit={props.handleLogin}>
                                <div className="form-group">
                                    <label>User Name *</label>
                                    <input type="text" className="form-control"  placeholder="Username*" name="email" onChange={props.handleChange} />
                                </div>

                                <div className="form-group">
                                    <label>Password *</label>
                                    <input type="password" className="form-control" placeholder="Password*" name="password" onChange={props.handleChange} />
                                </div>

                                <div className="form-group">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="flex-1">
                                            <input id="dd" className="checkbox-custom" name="dd" type="checkbox" />
                                            <label htmlFor="dd" className="checkbox-custom-label">Remember Me</label>
                                        </div>
                                        <div className="eltio_k2">
                                            <a href="#">Lost Your Password?</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-md full-width theme-bg text-light fs-md ft-medium">Login</button>
                                </div>
                            </form>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mfliud">                        
                        <h1 className="h3 mb-3 fw-normal">Please sign up</h1>
                            <form className="border p-3 rounded" onSubmit={handleAddUser}>
                                <input type="hidden" name="_token" value={token} ref={crsf_token} />
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>First Name *</label>
                                        <input type="text" className="form-control" placeholder="First Name" ref={first_name} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control" placeholder="Last Name" ref={last_name} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>Email *</label>
                                        <input type="text" className="form-control" placeholder="Email*" ref={email} />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>User Type</label>
                                        <select className="custom-select rounded" ref={user_type}>
                                            <option disabled>Select User Type </option>
                                           {
                                            userType && userType.map(item => <option value={item.id}>{item.name}</option>)
                                           }
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label>Password *</label>
                                        <input type="password" className="form-control" placeholder="Password*" ref={password} />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label>Confirm Password *</label>
                                        <input type="password" className="form-control" placeholder="Confirm Password*" ref={confirm_password} />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <p>By registering your details, you agree with our Terms & Conditions, and Privacy and Cookie Policy.</p>
                                </div>

                                <div className="form-group">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="flex-1">
                                            <input id="newsletter" className="checkbox-custom" name="newsletter" type="checkbox" ref={newsletter} />
                                            <label htmlFor="newsletter" className="checkbox-custom-label">Sign me up for the Newsletter!</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-md full-width theme-bg text-light fs-md ft-medium">Create An Account</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!-- ======================= Login End ======================== -->
        
        <!-- ======================= Newsletter Start ============================ --> */}
            <JobNotification></JobNotification>
            {/* <!-- ======================= Newsletter Start ============================ -->
        
        <!-- ============================ Footer Start ================================== --> */}
            <Footer {...props}></Footer>
            {/* <!-- ============================ Footer End ================================== --> */}



            <a id="back2Top" className="top-scroll" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>


        </>
    )
}

const mapStateToProps = state => {
    return {
        result: state.userReducer.data ? state.userReducer.data : []
    }
}

export default connect(mapStateToProps, { addUser })(SignUp);