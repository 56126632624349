const UserQualifications = (props) => {
    return (
        <div className="abt-cdt d-block full-width mb-4">
            <h4 className="ft-medium mb-1 fs-md">Qualification</h4>
            <div className="exslio-list mt-3">
                <ul>
                    {
                        props.qualifications && props.qualifications.map((item) => 
                        <li>
                                <div className="esclio-110 bg-light rounded px-3 py-3">
                                    <h4 className="mb-0 ft-medium fs-md">{ item.qualification }</h4>
                                    <div className="esclio-110-info full-width mb-2">
                                        <span className="text-muted mr-2"><i className="lni lni-graduation mr-1"></i>{ item.university }</span>
                                        <span className="text-muted mr-2"><i className="lni lni-calendar mr-1"></i>{ item.passing_year }</span>
                                    </div>
                                    <div className="esclio-110-decs full-width">
                                        <p>{ item.about_qualification }</p>
                                    </div>
                                </div>
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
export default UserQualifications;