import { ADD_USER, GET_USER, LOGIN_USER, UPDATE_PROFILE, UPDATE_QUALIFICATION, GET_USER_DETAIL, UPDATE_USER_COURSES } from "../action/types";

export default function (state = {}, action) {
    switch (action.type) {
        case ADD_USER:
            return { ...state, data: JSON.parse(action.payload) }
        case GET_USER:
            return { ...state, userProfileData: JSON.parse(action.payload) }
        case GET_USER_DETAIL:
            return { ...state, userDetail: JSON.parse(action.payload) }
        case LOGIN_USER:
            return { ...state, loginResponse: action.payload }
        case UPDATE_PROFILE:
            return { ...state, userProfileData: action.payload }
        case UPDATE_QUALIFICATION:
            return { ...state, userQualData: action.payload }
        case UPDATE_USER_COURSES:
            return { ...state, userCoursesData: action.payload }
        default:
            return state;
    }
}
