import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

const HomeSearch = (props) => {
    // const [locations, setLocations] = useState([]);
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        term: '',
        location: ''
    });
    useEffect(() => {
        // let locationsMd = props.commonData.data && props.commonData.data.locations && props.commonData.data.locations.data && props.commonData.data.locations.data.map(item => item);
        // let locationFiltered = locationsMd && locationsMd.filter((c, index) => {
        //     return locationsMd.indexOf(c) === index;
        // });
        // locationFiltered && setLocations(locationFiltered);
    }, [props.commonData]);

    const handleSearch = () => {
        (filter.term && filter.location) && navigate(`/search/${filter.term}/${filter.location}`, { replace: true });
    }

    const handleChange = (event) => {
        const name = event.target.name;
		const value = event.target.value;
		setFilter(values => ({...values, [name]: value}))
    }

    useEffect(() => {
        setFilter(values => ({...values, ['location']: localStorage.getItem('cityName')}));
    },[localStorage.getItem('cityName')])

    return(
        <div className="home-banner margin-bottom-0 intro-bg">
            <div className="container">
                
                <div className="row align-items-center justify-content-between">
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                        <div className="banner_caption text-left mb-4">
                            <div className="d-block mb-2"><span className="px-3 py-1 medium theme-bg-light theme-cl rounded">10,000+ Verified Profiles </span></div>
                            <h1 className="banner_title ft-bold mb-1">Find Online or Nearby<br/><span className="theme-cl">Education</span> Consultants</h1>
                            <p className="fs-md ft-regular">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
                        </div>
                        <form className="bg-white rounded p-1">
                            <div className="row no-gutters">
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                    <div className="form-group mb-0 position-relative">
                                        <input type="text" className="form-control lg left-ico" placeholder="Courses, Colleges or Consultants" name="term" onChange={handleChange} />
                                        <i className="bnc-ico lni lni-search-alt"></i>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="form-group mb-0 position-relative">
                                    <input type="text" className="form-control lg left-ico" placeholder="Delhi, Mumbai" name="location" defaultValue={filter.location} onChange={handleChange} readOnly />                                        
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12 col-12">
                                    <div className="form-group mb-0 position-relative">
                                        <button className="btn full-width custom-height-lg theme-bg text-white fs-md" type="button" onClick={handleSearch}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                        <div className="bnr_thumb position-relative">
                            <img src="assets/img/bn-2.png" className="img-fluid bnr_img" alt="" />
                            <div className="list_crs_img">
                                <img src="assets/img/img-1.png" className="img-fluid elsio cirl animate-fl-y" alt=""/>
                                <img src="assets/img/img-3.png" className="img-fluid elsio arrow animate-fl-x" alt=""/>
                                <img src="assets/img/img-2.png" className="img-fluid elsio moon animate-fl-x" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeSearch;