import { useEffect, useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";

const LoginModal = (props) => {
    const [loginErrFlag, setLoginErrFlag] = useState(false);
    useEffect(() => {
    }, [props.show]);

    useEffect(() => {
        props.loginResponse && props.loginResponse.code == 500 && setLoginErrFlag(true);
    },[props.loginResponse])
    return (
        <>

            <Modal show={props.show} onHide={props.handleClose}>
            <Alert key={"warning"} variant={"warning"} show={loginErrFlag || false}>
                    { loginErrFlag && props.loginResponse.message}
                </Alert>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-content" id="loginmodal">
                        
                        <div className="modal-headers">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="ti-close"></span>
                            </button>
                        </div>

                        <div className="modal-body p-5">
                            <div className="text-center mb-4">
                                <h2 className="m-0 ft-regular">Login</h2>
                            </div>

                            <form onSubmit={props.handleLogin}>
                                <div className="form-group">
                                    <label>User Name</label>
                                    <input type="text" className="form-control" placeholder="Username*" name="email" onChange={props.handleChange}/>
                                </div>

                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" className="form-control" placeholder="Password*" name="password" onChange={props.handleChange}/>
                                </div>

                                <div className="form-group">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="flex-1">
                                            <input id="dd" className="checkbox-custom" name="dd" type="checkbox" />
                                            <label htmlFor="dd" className="checkbox-custom-label">Remember Me</label>
                                        </div>
                                        <div className="eltio_k2">
                                            <a href="#" className="theme-cl">Lost Your Password?</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-md full-width theme-bg text-light fs-md ft-medium">Login</button>
                                </div>

                                <div className="form-group text-center mb-0">
                                    <p className="extra">Not a member?<a href="#et-register-wrap" className="text-dark"> Register</a></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default LoginModal;