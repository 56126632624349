import Header from "../Include/Header/header";
import { search } from "../../model/search";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { propTypes } from "react-bootstrap/esm/Image";
import SearchList from "./searchList";
import Pagination from "react-js-pagination";
const SearchResult = (props) => {
	const query = useParams();
	const [searchResult, setSearchResult] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [locations, setLocations] = useState([]);
	const navigate = useNavigate();
	const [filter, setFilter] = useState({
		term: '',
		location: ''
	});
	const [activePage, setActivePage] = useState(1);
	const [pageSetting, setPageSetting] = useState({
		total: 0,
		limit: 0
	});
	useEffect(() => {
		setFilter({
			'term': query['term'],
			'location': query['location']!==undefined ? query['location'] : localStorage.getItem('cityName')
		});
		props.search({
			'term': query['term'],
			'location': query['location']!==undefined ? query['location'] : localStorage.getItem('cityName')
		});
	}, [query.term]);

	useEffect(() => {
		// let locationsMd = props.commonData.data && props.commonData.data.locations.data.map(item => item);
		// setLocations(locationsMd);
	}, [props.commonData]);

	useEffect(() => {
		if (props.searchedData !== undefined && props.searchedData.data !== undefined) {
			setSearchResult(props.searchedData.data.data);
			setDataLoaded(true);
			setPageSetting({
				'total': props.searchedData?.data?.total,
				'limit': props.searchedData?.data?.limit
			});
		}
	}, [props.searchedData, dataLoaded])

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setFilter(values => ({ ...values, [name]: value }))
	}

	const handleSearch = () => {
		const post = { ...filter };
		let qrystr = '';
		if(!filter.term) {
			return;
		}
		qrystr = filter.term;
		if(filter.location !== undefined) {
			qrystr += '/' + filter.location;
		}
		navigate(`/search/${qrystr}`, { replace: true });
		props.search({
			'term': query['term'],
			'location': query['location']!==undefined ? query['location'] : localStorage.getItem('cityName'),
			'page': activePage
		});
		// props.search(post);
	}

	const handlePagination = (pageNumber) => {
		setActivePage(pageNumber);
		handleSearch();
	} 

	const handleSignup = () => {
		navigate(`/signup`, { replace: true });
	}
	return (
		<>
			{/* <Header header="header header-light dark-text"></Header> */}
			<div className="clearfix"></div>
			<div className="py-5 theme-bg" data-overlay="0">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-8 col-lg-10 col-md-12 col-sm-12 col-12">
							<div className="banner_caption text-center mb-2">
								<h2 className="ft-bold mb-4">Find the Best </h2>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- ======================= Searchbar Banner ======================== -->
			
			
			<!-- ======================= Filter Wrap Style 1 ======================== --> */}
			<section className="py-2 br-bottom br-top">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-xl-3 col-lg-4 col-md-5 col-sm-12">
							<h6 className="mb-0 ft-medium fs-sm">{ props?.searchedData?.data?.message }</h6>
						</div>

						{/* <div className="col-xl-9 col-lg-8 col-md-7 col-sm-12">
							<div className="filter_wraps elspo_wrap d-flex align-items-center justify-content-end">
								<div className="single_fitres mr-2 br-right">
									<select className="custom-select simple">
										<option value="1" selected="">Default Sorting</option>
										<option value="2">Recent</option>
										<option value="3">Featured</option>
										<option value="4">Online Training</option>
										<option value="5">Free Demo class</option>
									</select>
								</div>
								<div className="single_fitres">
									<a href="job-search-v1.html" className="simple-button mr-1"><i className="ti-layout-grid2"></i></a>
									<a href="job-list-v1.html" className="simple-button active theme-cl"><i className="ti-view-list"></i></a>
								</div>
							</div>
						</div> */}
					</div>

				</div>
			</section>
			{/* <!-- ============================= Filter Wrap ============================== --> */}

			{/* <!-- ============================ Main Section Start ================================== --> */}
			<section className="bg-light">
				<div className="container">
					<div className="row">

						<div className="col-lg-4 col-md-12 col-sm-12">
							<div className="bg-white rounded">

								<div className="sidebar_header d-flex align-items-center justify-content-between px-4 py-3 br-bottom">
									<h4 className="ft-medium fs-lg mb-0">Search Again</h4>
									<div className="ssh-header">
										<a href="javascript:void(0);" className="clear_all ft-medium text-muted">View More &#8623;</a>
										<a href="#search_open" data-toggle="collapse" aria-expanded="false" role="button" className="collapsed _filter-ico ml-2"><i className="lni lni-text-align-right"></i></a>
									</div>
								</div>

								{/* <!-- Find New Property --> */}
								<div className="sidebar-widgets collapse miz_show" id="search_open" data-parent="#search_open">

									<div className="search-inner">

										<div className="filter-search-box px-4 pt-3 pb-4">
											<div className="form-group">
												<input type="text" className="form-control" placeholder="Search by keywords..." name="term" onChange={handleChange} value={filter.term || ''} />
											</div>
											<div className="form-group">
												<input type="text" className="form-control" placeholder="Location, Zip.." name="location" onChange={handleChange} value={filter.location || ''} />
											</div>
											<button type="submit" className="btn btn-md theme-bg text-light rounded full-width" onClick={handleSearch}>Search</button>
										</div>

										<div className="form-group filter_button text-center pt-2 pb-4 px-4">
											<h4 className="fs-md mb-0 ft-medium">Didn't find what you looking for?</h4>
											<p>Submit your requirement to us we'll find the more suitable option and get back to you.</p>
											<button type="submit" className="btn btn-md bg-purple text-light rounded full-width">Contact us</button>
										</div>

										<div className="form-group filter_button text-center pt-2 pb-4 px-4">
											<h4 className="fs-md mb-0 ft-medium">Do you offer IBPS Exam Coaching ?</h4>
											<p>Sign up as a Tutor or as a Consultants</p>
											<button type="submit" className="btn btn-md bg-warning text-light rounded full-width" onClick={handleSignup}>Create Free Profile</button>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- Sidebar End --> */}

						</div>

						{/* <!-- Item Wrap Start --> */}
						<div className="col-lg-8 col-md-12 col-sm-12">

							{/* <!-- row --> */}
							<div className="row align-items-center">

								{/* <!-- Single --> */}

								{
									searchResult && searchResult.map((item) => <SearchList key={item.id} info={item}></SearchList>)
								}
								<Pagination
								activePage={activePage}
								itemsCountPerPage={pageSetting.limit}
								totalItemsCount={pageSetting.total}
								pageRangeDisplayed={5}
								onChange={handlePagination}
								></Pagination>
							</div>
							{/* <!-- row --> */}

							{/* <div className="row">
								<div className="col-lg-12 col-md-12 col-sm-12">
									<ul className="pagination">
										<li className="page-item">
											<a className="page-link" href="#" aria-label="Previous">
												<span className="fas fa-arrow-circle-right"></span>
												<span className="sr-only">Previous</span>
											</a>
										</li>
										<li className="page-item"><a className="page-link" href="#">1</a></li>
										<li className="page-item"><a className="page-link" href="#">2</a></li>
										<li className="page-item active"><a className="page-link" href="#">3</a></li>
										<li className="page-item"><a className="page-link" href="#">...</a></li>
										<li className="page-item"><a className="page-link" href="#">18</a></li>
										<li className="page-item">
											<a className="page-link" href="#" aria-label="Next">
												<span className="fas fa-arrow-circle-right"></span>
												<span className="sr-only">Next</span>
											</a>
										</li>
									</ul>
								</div>
							</div> */}

						</div>

					</div>
				</div>
			</section>
			{/* <!-- ============================ Main Section End ================================== --> */}
		</>
	);
}

const mapStateToProps = state => {
	return {
		searchedData: (state.searchReducer.searchedResult && state.searchReducer.searchedResult.data !== undefined) ? state.searchReducer.searchedResult : [],
	}
}

export default connect(mapStateToProps, { search })(SearchResult);