import EnquiryModal from "../Enquiry/enquiryModal";
import CityModal from "../Home/cityModal";
import Footer from "../Include/Header/footer";
import Header from "../Include/Header/header";
import LoginModal from "../Popup/loginmodal";
import MsgToast from "../Popup/msgToast";

const Layout = (props) => {
    return (
        <div id="main-wrapper">
            <Header {...props} header={props.header}></Header>
            {props.children}
            <Footer></Footer>
            <LoginModal {...props}></LoginModal>
            <MsgToast {...props}></MsgToast>
            <a id="back2Top" className="top-scroll" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
            {
                (localStorage.getItem('isCityConfirmed') === null || localStorage.getItem('isCityConfirmed') === false) && <CityModal {...props}></CityModal>
            }
        </div>
    );
}

export default Layout;