export const ADD_USER = 'addUser';
export const GET_COMMON = 'getCommonApi';
export const BASE_URL = 'http://127.0.0.1:8000/';
export const GET_USER = 'getUser';
export const LOGIN_USER = 'loginUser';
export const UPDATE_PROFILE = 'updateProfile';
export const UPDATE_QUALIFICATION = 'updateQualificataion';
export const SEARCH = 'search';
export const GET_LOC_BY_PIN_CITY = 'Get Location By Pincode Or City';
export const GET_PORTAL_NAVS = 'getPortalNavs';
export const GET_USER_DETAIL = 'getUserDetail';
export const UPDATE_USER_COURSES = 'updateUserCourses';
export const ADD_USER_ENQUIRY = 'addUserEnquiry';
export const GET_LOC_BY_PIN_OR_CITY = 'Get Location By Pincode Or City sdfgdfsg';
