import { useEffect, useState } from "react";

const Course = (props) => {

    return (
        <div className="_dashboard_content_body py-3 px-3">
            <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <div className="form-group">
                        <label className="text-dark ft-medium">Courses</label>
                        <select className="custom-select rounded" name="course_id" onChange={e => props.handleInputChange(e, props._index)}>
                            <option value="">Select Course</option>
                            {
                                props.courses && props.courses.map(item => <option key={item.id} value={item.id} selected={props.offeredCourse && props.offeredCourse.course_id === item.id ? "selected" : ""}  >{item.course}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <div className="form-group">
                        <label className="text-dark ft-medium">Teaching Mode</label>
                        <select className="custom-select rounded" name="teaching_mode" onChange={e => props.handleInputChange(e, props._index)} >
                            <option value="">Select Teaching Mode</option>
                            {
                                props?.teachingMode?.map(item => <option key={item.master_key} value={item.master_key} selected={props.offeredCourse && props.offeredCourse.teaching_mode === item.master_key ? "selected" : ""}>{item.master_value}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                    <div className="form-group">
                        <label className="text-dark ft-medium">Fee Type</label>
                        <select className="custom-select rounded" name="fee_type" onChange={e => props.handleInputChange(e, props._index)}>
                            <option value="">Select Fee Type</option>
                            {
                                props?.feeType?.map(item => <option key={item.master_key} value={item.master_key} selected={props.offeredCourse && props.offeredCourse.fee_type === item.master_key ? "selected" : ""}>{item.master_value}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                    <div className="form-group">
                        <label className="text-dark ft-medium">Fee</label>
                        <input type="text" name="fee" className="form-control rounded" placeholder="Fee" onChange={e => props.handleInputChange(e, props._index)} defaultValue={props.offeredCourse && props.offeredCourse.fee} />
                    </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                    <div className="form-group">
                        <label className="text-dark ft-medium">Teaching Experience</label>
                        <input type="text" name="experience" className="form-control rounded" placeholder="Teaching Experience" onChange={e => props.handleInputChange(e, props._index)} defaultValue={props?.offeredCourse?.experience} />
                    </div>
                </div>
                <div className="col-xl-12 col-lg-12">
                    <div className="form-group">
                        <label className="text-dark ft-medium">About Course & Experience</label>
                        <textarea className="form-control with-light" name="about_course" defaultValue={props.offeredCourse && props.offeredCourse.about_course} onChange={e => props.handleInputChange(e, props._index)}></textarea>
                    </div>
                </div>
                <div className="col-xl-1 col-lg-1">
                    <div className="form-group">
                        <button type="button" className="btn btn-md ft-medium text-light rounded theme-bg" onClick={() => props.addMore()}>Add</button>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-2">
                    <div className="form-group">
                        <button type="button" className="btn btn-md ft-medium text-light rounded theme-bg" onClick={() => props.removeCourse(props.offeredCourse._id)}>Remove</button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Course;