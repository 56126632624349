import { useEffect, useState } from "react";
import { Modal, Button, Alert, Toast } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { addEnquiry } from "../../model/enquiry";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { NOTIFY_ACTION_ERROR, NOTIFY_ACTION_SUCCESS } from "../../config/constant";
import { ADD_USER_ENQUIRY } from "../../action/types";
import { notify } from "../../model/common";

const EnquiryModal = (props) => {
    const dispatch = useDispatch();
    const [courses, setCourses] = useState([]);
    const [availalbeTeachingMode, setAvailableTeachingMode] = useState([]);
    const [enquiry, setEnquiry] = useState({id: props.userDetail?.data?.id, tutor_name: props.userDetail?.data?.first_name +' '+props.userDetail?.data?.last_name});
    props.courses?.map((item) =>
        !availalbeTeachingMode.includes(item.teaching_mode) && setAvailableTeachingMode(values => ([...values, item.teaching_mode]))
    );
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setEnquiry(values => ({ ...values, [name]: value }))
    }

    const handleAddEnquiry = async (e) => {
        e.preventDefault();
        await props.addEnquiry(enquiry);
        props.handleClose();
    }

    // const notify = (notifyAction, content) => {
    //     if (notifyAction === NOTIFY_ACTION_SUCCESS) {
    //         toast.success(content ? content : "Success", {
    //             position: toast.POSITION.TOP_RIGHT
    //         });
    //     }

    //     if (notifyAction === NOTIFY_ACTION_ERROR) {
    //         toast.error(content ? content : "Failed", {
    //             position: toast.POSITION.TOP_RIGHT
    //         });
    //     }
    // };

    useEffect(() => {
        props?.userProfileData?.data?.userCourses && setCourses(props.userProfileData.data.userCourses);
    }, [props.userProfileData])

    useEffect(() => {
        if (props.enquiryStatus?.code === "200") {
            notify(NOTIFY_ACTION_SUCCESS, props.enquiryStatus?.message);
        } else if (props.enquiryStatus !== null && props.enquiryStatus?.code !== "200") {
            notify(NOTIFY_ACTION_ERROR, props.enquiryStatus?.message);
        }
        dispatch({ type: ADD_USER_ENQUIRY, payload: null});

    }, [props.enquiryStatus])
    return (
        <>
            <ToastContainer />
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enquiry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-content" id="enquirymodal">


                        <div className="modal-body p-5">

                            <form onSubmit={handleAddEnquiry}>
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" className="form-control" placeholder="Name*" name="customer_name" key="customer_name" onChange={handleChange} />
                                </div>

                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input type="text" className="form-control" placeholder="Phone Number*" name="phone_number" key="phone_number" onChange={handleChange} />
                                </div>

                                <div className="form-group">
                                    <label>Email ID</label>
                                    <input type="text" className="form-control" placeholder="Email*" name="email_id" key="email_id" onChange={handleChange} />
                                </div>

                                <div className="form-group">
                                    <label>Courses</label>
                                    <select className="custom-select rounded" name="course_id" key="course_id" onChange={handleChange} >
                                        <option value="" key={"Select Course"}>Select Course</option>
                                        {
                                            props.courses && props.courses.map(item => <option key={item.course_id} value={item.course_id} >{item.course}</option>)
                                        }
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>Teaching Mode</label>
                                    <option value="" key={"Select Teaching Mode"}>Select Teaching Mode</option>
                                    <select className="custom-select rounded" name="teaching_mode"  key="teaching_mode" onChange={handleChange}>
                                        {
                                            availalbeTeachingMode && availalbeTeachingMode.map(teaching_mode => <option key={teaching_mode} value={teaching_mode} >{teaching_mode}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <textarea name="message" key="message" placeholder="message" className="form-control" onChange={handleChange}></textarea>
                                </div>


                                <div className="form-group">
                                    <button type="submit" className="btn btn-md full-width theme-bg text-light fs-md ft-medium">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

const mapStateToProps = state => {
    return {
        userProfileData: state.userReducer.userProfileData ? state.userReducer.userProfileData : [],
        enquiryStatus: state.enquiryReducer?.data ? state.enquiryReducer?.data : null
    }
}

export default connect(mapStateToProps, { addEnquiry })(EnquiryModal);