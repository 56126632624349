export const isLogin = () => {
    const cyuva = JSON.parse(localStorage.getItem('cyuva'));
    return cyuva && cyuva.userAuthToken && cyuva.userAuthToken.length > 0 ? true : false;
} 

export const saveAuthToken = (headers) => {
    let userAuthToken = headers && headers.userauthtoken;
    let cyuva = localStorage.getItem('cyuva') ? JSON.parse(localStorage.getItem('cyuva')) : {};
    cyuva.userAuthToken = userAuthToken;
    localStorage.setItem('cyuva', JSON.stringify(cyuva));
}

export const getAuthToken = () => {
    const cyuva = JSON.parse(localStorage.getItem('cyuva'));
    return (cyuva && cyuva.userAuthToken) && cyuva.userAuthToken;
}