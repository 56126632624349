import { useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
const MsgToast = (props) => {
    const [toastShow, setToastShow] = useState(false);

    useEffect(()=> {
        console.log(props);
        props.toastShow && setToastShow(props.toastShow);    
    },[props.toastShow])
    
    const handleClose = () => {
        setToastShow(false);
    }
    return (
        <Row>
      <Col xs={6}>
        <Toast onClose={() => handleClose()} show={props.toastShow} delay={3000} autohide>
          <Toast.Header>
            {/* <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            /> */}
            <strong className="me-auto">{ props.heading }</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>{ props.message }</Toast.Body>
        </Toast>
        </Col>
    </Row>
    );
}

export default MsgToast;