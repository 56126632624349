import { useEffect, useState } from "react";
import { Modal, Button, Alert, Toast } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { NOTIFY_ACTION_ERROR, NOTIFY_ACTION_SUCCESS } from "../../config/constant";
import { GET_LOC_BY_PIN_OR_CITY } from "../../action/types";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { getCityList } from "../../model/common";


const CityModal = (props) => {
    const dispatch = useDispatch();
    const [courses, setCourses] = useState([]);
    const [availalbeTeachingMode, setAvailableTeachingMode] = useState([]);
    const [enquiry, setEnquiry] = useState({});
    const [searchedItems, setSearchedItems] = useState([]);
    const [cityName, setCityName] = useState(null);
    
    props.courses?.map((item) =>
        !availalbeTeachingMode.includes(item.teaching_mode) && setAvailableTeachingMode(values => ([...values, item.teaching_mode]))
    );

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (value && value.length > 3) {
            
        }
        // setEnquiry(values => ({ ...values, [name]: value }))
    }

    const handleAddEnquiry = async (e) => {
        e.preventDefault();
        await props.addEnquiry(enquiry);
        props.handleClose();
    }
    useEffect(() => {
        props.cities && setSearchedItems(props.cities);
    },[props.cities]);

    const items = [
        // {
        //   id: 0,
        //   name: 'Cobol'
        // },
        // {
        //   id: 1,
        //   name: 'JavaScript'
        // },
        // {
        //   id: 2,
        //   name: 'Basic'
        // },
        // {
        //   id: 3,
        //   name: 'PHP'
        // },
        // {
        //   id: 4,
        //   name: 'Java'
        // }
      ]
    
      const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        if (string && string.length > 1) {
            props.getCityList(string);
        }
        
      }
    
      const handleOnHover = (result) => {
        // the item hovered
      }
    
      const handleOnSelect = (item) => {
        // the item selected
        setCityName(item.name);
      }
    
      const handleOnFocus = () => {
      }
    
      /* const formatResult = (item) => {
        return (
          <>
            <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span>
            <span style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span>
          </>
        )
      } */

      const handleUpdateUserCity = (e) => {
        e.preventDefault();
        localStorage.setItem('cityName', cityName);
        localStorage.setItem('isCityConfirmed', true);
        props.handleCloseCityModal();
      }

    return (
        <>
            <ToastContainer />
            <Modal show={props.showCityModal} onHide={props.handleCloseCityModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Search Tutor in you city</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-content" id="enquirymodal">
                        <div className="modal-body p-5">

                            <form onSubmit={handleUpdateUserCity}>
                                <div className="form-group">
                                    <label>City</label>
                                    {/* <input type="text" className="form-control" placeholder="Select your city*" name="city_name" key="city_name" onChange={handleChange} /> */}
                                    <ReactSearchAutocomplete
                                    items={searchedItems}
                                    onSearch={handleOnSearch}
                                    onHover={handleOnHover}
                                    onSelect={handleOnSelect}
                                    onFocus={handleOnFocus}
                                    autoFocus
                                />
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn btn-md full-width theme-bg text-light fs-md ft-medium">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleCloseCityModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

const mapStateToProps = state => {
    return {
        cities: state.commonReducer?.citylist?.data?.data ? state.commonReducer?.citylist.data.data : []
    }
}

export default connect(mapStateToProps, { getCityList })(CityModal);