import axios from "axios";
import { ADD_USER_ENQUIRY, BASE_URL } from "../action/types"
import { getAuthToken, saveAuthToken } from "../Services/util";

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';



export const addEnquiry = (values, history) => async dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getAuthToken()
      }
    axios.post(BASE_URL +'api/admin/lead/generate', values, {headers: headers})
    .then((result) => {
        dispatch({ type: ADD_USER_ENQUIRY, payload: result.data});
    })
    .catch((err) => {
        dispatch({ type: ADD_USER_ENQUIRY, payload: null});
    });
    
}