import { useEffect, useState } from "react";
import Footer from "../Include/Header/footer";
import Header from "../Include/Header/header";
import LoginModal from "../Popup/loginmodal";
import ActiveJobs from "./activejobs";
import CityModal from "./cityModal";
import Feedback from "./feedback";
import HomeSearch from "./homesearch";
import JobNotification from "./jobnotification";
import PopularJobs from "./popularjobs";
import Stats from "./stats";
import TopCandidates from "./topcandidates";
import TopCategories from "./topcategories";

const Home = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
  const handleShow = (e) => {
      e.preventDefault();
      setShow(true);
  };
  useEffect(()=> {
    console.log('here');
    props.configureHeader("header header-transparent dark-text");
    /* if(localStorage.getItem('isCityConfirmed') === null || localStorage.getItem('isCityConfirmed') === false) {
      setShow(true);
    } */
  },[])

  // component
    return(
<div id="main-wrapper">
		
        {/* <Header {...props}></Header> */}
        
        <HomeSearch {...props}></HomeSearch>
        
        <Stats></Stats>
        
        <ActiveJobs></ActiveJobs>
        
        <TopCategories></TopCategories>
        
        <PopularJobs></PopularJobs>
        
        <Feedback></Feedback>
        
        <TopCandidates></TopCandidates>
        
        <JobNotification></JobNotification>
        
        {/* <Footer></Footer> */}
        
        {/* <LoginModal {...props}></LoginModal> */}
        <a id="back2Top" className="top-scroll" title="Back to top" href="#"><i className="ti-arrow-up"></i></a>
        
    </div>
    )
}

export default Home;