const TopCategories = () => {
    return(
        <section className="space gray">
            <div className="container">
            
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="sec_title position-relative text-center mb-5">
                            <h6 className="text-muted mb-0">Popular Categories</h6>
                            <h2 className="ft-bold">Browse Top Categories</h2>
                        </div>
                    </div>
                </div>
                
                
                <div className="row align-items-center">
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-laptop-phone fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Software Company</h4>
                                    <span className="text-muted">607 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-cloud fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Cloud Computing</h4>
                                    <span className="text-muted">960 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-shopify fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Logistics/Shipping</h4>
                                    <span className="text-muted">438 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-construction fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Engineering Services</h4>
                                    <span className="text-muted">644 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-phone-set fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Telecom/ Internet</h4>
                                    <span className="text-muted">380 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-sthethoscope fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Healthcare/Pharma</h4>
                                    <span className="text-muted">472 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-money-protection fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Finance/Insurance</h4>
                                    <span className="text-muted">654 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-diamond fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Product Software</h4>
                                    <span className="text-muted">732 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-briefcase fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Diversified/Retail</h4>
                                    <span className="text-muted">610 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-graduation fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Education</h4>
                                    <span className="text-muted">960 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-mastercard fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Banking/BPO</h4>
                                    <span className="text-muted">740 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
                        <div className="cats-wrap text-center">
                            <a href="job-search-v1.html" className="cats-box d-block rounded bg-white px-2 py-4">
                                <div className="text-center mb-2 mx-auto position-relative d-inline-flex align-items-center justify-content-center p-3 theme-bg-light circle"><i className="lni lni-gallery fs-lg theme-cl"></i></div>
                                <div className="cats-box-caption">
                                    <h4 className="fs-md mb-0 ft-medium m-catrio">Printing & Packaging</h4>
                                    <span className="text-muted">425 Jobs</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                </div>
                
                
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="position-relative text-center">
                            <a href="browse-category.html" className="btn btn-md bg-dark rounded text-light hover-theme">Browse All Categories<i className="lni lni-arrow-right-circle ml-2"></i></a>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default TopCategories;