import '../../../assets/css/plugins/bootstrap.min.css';
import LineIcon from "react-lineicons";
import { isLogin } from '../../../Services/util';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';

const Header = (props) => {

    const [login, setLogin] = useState(false);
    const [portalNavs, setPortalNavs] = useState([]);
    const showMsg = (e) => {
        e.preventDefault();
    }
    useEffect(() => {
        props.portalNavs && setPortalNavs(props.portalNavs.data);
    }, [props.portalNavs, portalNavs])

    return (
        <>
        {
                                    portalNavs && portalNavs.map((item) => {
                                        
                                        
                                        <>
                                            <p>{ item.category }</p>
                                        </>
                                    
                                    })
                                }
            <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                <Container>
                    <Navbar.Brand><Link to="/">Career Yuva</Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">                            
                            {
                                    portalNavs && portalNavs.map(item => {
                                        return(
                                            <NavDropdown key={item.category} title={ item.category } id="collasible-nav-dropdown">
                                                <div className='row'>
                                                {
                                                    item.subMenu && item.subMenu.map(subMenu => {
                                                        return(
                                                        
                                                            <div className='colxl-6 col-lg-6 col-md-6'>
                                                                <NavDropdown.ItemText href="#action/3.1"  key={subMenu.category}>{subMenu.category}</NavDropdown.ItemText>
                                                                <NavDropdown.Divider />
                                                                {
                                                                    subMenu.courses && subMenu.courses.map((crs) => <div><Link key={crs[0].course} to={"/search/"+ crs[0].course + "/" + localStorage.getItem('cityName')} >{crs[0].course}</Link></div> )
                                                                }
                                                            </div>
                                                        

                                                        )
                                                    })
                                                }
                                                </div>
                                            </NavDropdown>
                                        )
                                        
                                    
                                    })
                                }
                        </Nav>
                        <Nav>
                            {
                                !isLogin() &&
                                <Nav.Link href="#" onClick={props.handleShow} >Sign In</Nav.Link>
                            }
                            {
                                isLogin() &&
                                <Nav.Link href="#" onClick={props.handleLogout} >Sign Out</Nav.Link>
                            }


                            {
                                !isLogin() &&
                                <Nav.Link eventKey={2}>
                                    <Link to="/signup">Register</Link>
                                </Nav.Link>
                            }
                            {
                                isLogin() &&
                                <Nav.Link eventKey={2} to="#memes">
                                    <Link to="/profile">Profile</Link>
                                </Nav.Link>
                            }
                            {
                                <Nav.Link href="#" onClick={props.handleShowCityModal} >Change City</Nav.Link>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>


            <div className="clearfix"></div>
        </>
    );
}

export default Header;