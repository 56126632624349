const ActiveJobs = () => {
    return(
        <section className="middle">
            <div className="container">
            
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="sec_title position-relative text-center mb-5">
                            <h6 className="text-muted mb-0">Recent Jobs</h6>
                            <h2 className="ft-bold">Recent Active <span className="theme-cl">Listed jobs</span></h2>
                        </div>
                    </div>
                </div>
                
                
                <div className="row align-items-center">
                
                    
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="jbr-wrap text-left border rounded">
                            <div className="cats-box mlb-res rounded bg-white d-flex align-items-center justify-content-between px-3 py-3">
                                <div className="cats-box rounded bg-white d-flex align-items-center">
                                    <div className="text-center"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="55" alt=""/></div>
                                    <div className="cats-box-caption px-2">
                                        <h4 className="fs-md mb-0 ft-medium">Fresher UI/UX Designer (3 Year Exp.)</h4>
                                        <div className="d-block mb-2 position-relative">
                                            <span className="text-muted medium"><i className="lni lni-map-marker mr-1"></i>Liverpool, London</span>
                                            <span className="muted medium ml-2 theme-cl"><i className="lni lni-briefcase mr-1"></i>Full Time</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mlb-last"><a href="job-detail.html" className="btn gray ft-medium apply-btn fs-sm rounded">Apply Job<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="jbr-wrap text-left border rounded">
                            <div className="cats-box mlb-res rounded bg-white d-flex align-items-center justify-content-between px-3 py-3">
                                <div className="cats-box rounded bg-white d-flex align-items-center">
                                    <div className="text-center"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="55" alt=""/></div>
                                    <div className="cats-box-caption px-2">
                                        <h4 className="fs-md mb-0 ft-medium">Fresher UI/UX Designer (3 Year Exp.)</h4>
                                        <div className="d-block mb-2 position-relative">
                                            <span className="text-muted medium"><i className="lni lni-map-marker mr-1"></i>Liverpool, London</span>
                                            <span className="muted medium ml-2 text-purple"><i className="lni lni-briefcase mr-1"></i>Contract</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mlb-last"><a href="job-detail.html" className="btn gray ft-medium apply-btn fs-sm rounded">Apply Job<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="jbr-wrap text-left border rounded">
                            <div className="cats-box mlb-res rounded bg-white d-flex align-items-center justify-content-between px-3 py-3">
                                <div className="cats-box rounded bg-white d-flex align-items-center">
                                    <div className="text-center"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="55" alt=""/></div>
                                    <div className="cats-box-caption px-2">
                                        <h4 className="fs-md mb-0 ft-medium">Fresher UI/UX Designer (3 Year Exp.)</h4>
                                        <div className="d-block mb-2 position-relative">
                                            <span className="text-muted medium"><i className="lni lni-map-marker mr-1"></i>Liverpool, London</span>
                                            <span className="muted medium ml-2 text-warning"><i className="lni lni-briefcase mr-1"></i>Part Time</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mlb-last"><a href="job-detail.html" className="btn gray ft-medium apply-btn fs-sm rounded">Apply Job<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="jbr-wrap text-left border rounded">
                            <div className="cats-box mlb-res rounded bg-white d-flex align-items-center justify-content-between px-3 py-3">
                                <div className="cats-box rounded bg-white d-flex align-items-center">
                                    <div className="text-center"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="55" alt=""/></div>
                                    <div className="cats-box-caption px-2">
                                        <h4 className="fs-md mb-0 ft-medium">Fresher UI/UX Designer (3 Year Exp.)</h4>
                                        <div className="d-block mb-2 position-relative">
                                            <span className="text-muted medium"><i className="lni lni-map-marker mr-1"></i>Liverpool, London</span>
                                            <span className="muted medium ml-2 text-danger"><i className="lni lni-briefcase mr-1"></i>Internship</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mlb-last"><a href="job-detail.html" className="btn gray ft-medium apply-btn fs-sm rounded">Apply Job<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="jbr-wrap text-left border rounded">
                            <div className="cats-box mlb-res rounded bg-white d-flex align-items-center justify-content-between px-3 py-3">
                                <div className="cats-box rounded bg-white d-flex align-items-center">
                                    <div className="text-center"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="55" alt=""/></div>
                                    <div className="cats-box-caption px-2">
                                        <h4 className="fs-md mb-0 ft-medium">Fresher UI/UX Designer (3 Year Exp.)</h4>
                                        <div className="d-block mb-2 position-relative">
                                            <span className="text-muted medium"><i className="lni lni-map-marker mr-1"></i>Liverpool, London</span>
                                            <span className="muted medium ml-2 theme-cl"><i className="lni lni-briefcase mr-1"></i>Full Time</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mlb-last"><a href="job-detail.html" className="btn gray ft-medium apply-btn fs-sm rounded">Apply Job<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="jbr-wrap text-left border rounded">
                            <div className="cats-box mlb-res rounded bg-white d-flex align-items-center justify-content-between px-3 py-3">
                                <div className="cats-box rounded bg-white d-flex align-items-center">
                                    <div className="text-center"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="55" alt=""/></div>
                                    <div className="cats-box-caption px-2">
                                        <h4 className="fs-md mb-0 ft-medium">Fresher UI/UX Designer (3 Year Exp.)</h4>
                                        <div className="d-block mb-2 position-relative">
                                            <span className="text-muted medium"><i className="lni lni-map-marker mr-1"></i>Liverpool, London</span>
                                            <span className="muted medium ml-2 text-purple"><i className="lni lni-briefcase mr-1"></i>Contract</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mlb-last"><a href="job-detail.html" className="btn gray ft-medium apply-btn fs-sm rounded">Apply Job<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="jbr-wrap text-left border rounded">
                            <div className="cats-box mlb-res rounded bg-white d-flex align-items-center justify-content-between px-3 py-3">
                                <div className="cats-box rounded bg-white d-flex align-items-center">
                                    <div className="text-center"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="55" alt=""/></div>
                                    <div className="cats-box-caption px-2">
                                        <h4 className="fs-md mb-0 ft-medium">Fresher UI/UX Designer (3 Year Exp.)</h4>
                                        <div className="d-block mb-2 position-relative">
                                            <span className="text-muted medium"><i className="lni lni-map-marker mr-1"></i>Liverpool, London</span>
                                            <span className="muted medium ml-2 text-danger"><i className="lni lni-briefcase mr-1"></i>Internship</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mlb-last"><a href="job-detail.html" className="btn gray ft-medium apply-btn fs-sm rounded">Apply Job<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="jbr-wrap text-left border rounded">
                            <div className="cats-box mlb-res rounded bg-white d-flex align-items-center justify-content-between px-3 py-3">
                                <div className="cats-box rounded bg-white d-flex align-items-center">
                                    <div className="text-center"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="55" alt=""/></div>
                                    <div className="cats-box-caption px-2">
                                        <h4 className="fs-md mb-0 ft-medium">Fresher UI/UX Designer (3 Year Exp.)</h4>
                                        <div className="d-block mb-2 position-relative">
                                            <span className="text-muted medium"><i className="lni lni-map-marker mr-1"></i>Liverpool, London</span>
                                            <span className="muted medium ml-2 text-warning"><i className="lni lni-briefcase mr-1"></i>Part Time</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mlb-last"><a href="job-detail.html" className="btn gray ft-medium apply-btn fs-sm rounded">Apply Job<i className="lni lni-arrow-right-circle ml-1"></i></a></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="position-relative text-center">
                            <a href="job-search-v1.html" className="btn btn-md theme-bg rounded text-light hover-theme">Explore More Jobs<i className="lni lni-arrow-right-circle ml-2"></i></a>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    );
}

export default ActiveJobs;