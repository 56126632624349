import { combineReducers } from 'redux';
import commonReducer from './commonReducer';
import searchReducer from './searchReducer';
import enquiryReducer from './enquiryReducer';

import userReducer from './userReducer';
export default combineReducers({
  userReducer: userReducer,
  commonReducer: commonReducer,
  searchReducer: searchReducer,
  enquiryReducer: enquiryReducer
});