const Feedback = () => {
    return(
        <section className="middle gray">
            <div className="container">
            
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="sec_title position-relative text-center mb-5">
                            <h6 className="text-muted mb-0">Our Reviews</h6>
                            <h2 className="ft-bold">What Our Customer <span className="theme-cl">Saying</span></h2>
                        </div>
                    </div>
                </div>
                
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="review-slide px-3">
                            
                            
                            <div className="single_review px-2">
                                <div className="reviews_wrap position-relative bg-white rounded py-4 px-4">
                                    <div className="rw-header d-flex align-items-center justify-content-start">
                                        <div className="rv-110-thumb position-relative verified-author"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></div>
                                        <div className="rv-110-caption pl-3">
                                            <h4 className="ft-medium fs-md mb-0 lh-1">Alvin B. Washington</h4>
                                            <p className="p-0 m-0">Co Founder</p>
                                        </div>
                                    </div>
                                    <div className="rw-header d-flex mt-3">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="single_review px-2">
                                <div className="reviews_wrap position-relative bg-white rounded py-4 px-4">
                                    <div className="rw-header d-flex align-items-center justify-content-start">
                                        <div className="rv-110-thumb"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></div>
                                        <div className="rv-110-caption pl-3">
                                            <h4 className="ft-medium fs-md mb-0 lh-1">Lavera C. Clifford</h4>
                                            <p className="p-0 m-0">Team Manager</p>
                                        </div>
                                    </div>
                                    <div className="rw-header d-flex mt-3">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="single_review px-2">
                                <div className="reviews_wrap position-relative bg-white rounded py-4 px-4">
                                    <div className="rw-header d-flex align-items-center justify-content-start">
                                        <div className="rv-110-thumb position-relative verified-author"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></div>
                                        <div className="rv-110-caption pl-3">
                                            <h4 className="ft-medium fs-md mb-0 lh-1">Linda S. Riggs</h4>
                                            <p className="p-0 m-0">Project Manager</p>
                                        </div>
                                    </div>
                                    <div className="rw-header d-flex mt-3">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="single_review px-2">
                                <div className="reviews_wrap position-relative bg-white rounded py-4 px-4">
                                    <div className="rw-header d-flex align-items-center justify-content-start">
                                        <div className="rv-110-thumb"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></div>
                                        <div className="rv-110-caption pl-3">
                                            <h4 className="ft-medium fs-md mb-0 lh-1">Chris L. Hazel</h4>
                                            <p className="p-0 m-0">Web Designer</p>
                                        </div>
                                    </div>
                                    <div className="rw-header d-flex mt-3">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="single_review px-2">
                                <div className="reviews_wrap position-relative bg-white rounded py-4 px-4">
                                    <div className="rw-header d-flex align-items-center justify-content-start">
                                        <div className="rv-110-thumb position-relative verified-author"><img src="https://via.placeholder.com/500x500" className="img-fluid circle" width="70" alt="" /></div>
                                        <div className="rv-110-caption pl-3">
                                            <h4 className="ft-medium fs-md mb-0 lh-1">Mark Jukerberg</h4>
                                            <p className="p-0 m-0">PHP Developer</p>
                                        </div>
                                    </div>
                                    <div className="rw-header d-flex mt-3">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Feedback;