import { Link } from "react-router-dom";

const DashboardNavigation = (props) => {
    return(
        <>
        <a className="mobNavigation" data-toggle="collapse" href="#MobNav" role="button" aria-expanded="false" aria-controls="MobNav">
					<i className="fas fa-bars mr-2"></i>Dashboard Navigation
				</a>
				 <div className="collapse" id="MobNav">
					<div className="dashboard-nav">
						<div className="dashboard-inner">
							<ul data-submenu-title="Main Navigation">
								<li><Link to="/profile"><i className="lni lni-dashboard mr-2"></i>Dashboard</Link></li>
								<li><Link to="/profile/qualification"><i className="lni lni-certificate mr-2"></i>Manage Qualification</Link></li>
								<li><Link to="/profile/courses"><i className="lni lni-book mr-2"></i>Manage Courses</Link></li>
							</ul>
							<ul data-submenu-title="My Accounts">
								<li className="active"><Link to="/profile"><i className="lni lni-user mr-2"></i>My Profile </Link></li>
								<li><a href="/profile"><i className="lni lni-lock-alt mr-2"></i>Change Password</a></li>
								<li><a href="login.html"><i className="lni lni-power-switch mr-2"></i>Log Out</a></li>
							</ul>
						</div>					
					</div>
				</div>
        </>
    )
}

export default DashboardNavigation;