import logo from './logo.svg';
import './App.css';
import './assets/css/plugins/animation.css';
import './assets/css/plugins/bootstrap.min.css';
import './assets/css/plugins/snackbar.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/css/plugins/themify.css';
// import './assets/css/plugins/line-icons.css';
import './assets/css/plugins/iconfont.css';
import './assets/css/plugins/font-awesome.css';
import './assets/css/plugins/flaticon.css';
import './assets/css/styles.css';
import Home from './Component/Home/home';
import SignUp from './Component/Auth/signup';
import Profile from './Component/Account/profile';
import { useEffect, useState } from 'react';
import { getCommon, getPortalNavigation } from './model/common';
import { connect, shallowEqual } from 'react-redux';
import { loginUser } from './model/user';
import SearchResult from './Component/Search/SearchResult';
import Layout from './Component/Layout/Layout';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Qualifications from './Component/Account/Qualification/qualifications';
import Detail from './Component/Search/detail';
import Courses from './Component/Account/Course/courses';

const App = (props) => {

  let navigate = useNavigate();
  const [header, setHeader] = useState("header header-light dark-text");
  const [show, setShow] = useState(false);
  const [showCityModal, setShowCityModal] = useState(true);
  const [loginInfo, setLoginInfo] = useState({});
  useEffect(() => {
    props.getCommon();
    props.getPortalNavigation();
  }, []);

  useEffect(() => {
    if(props.loginResponse && props.loginResponse.code==200) {
      setShow(false);
      navigate("/profile", { replace: true });
    }
  },[props.loginResponse]);

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const handleShowCityModal = () => {
    localStorage.removeItem('isCityConfirmed');
    setShowCityModal(true);
  }
  const handleCloseCityModal = () => setShowCityModal(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setLoginInfo(values => ({ ...values, [name]: value }))
  }

  const handleLogin = async (event) => {
    event.preventDefault();
    await props.loginUser(loginInfo);
  }

  const handleLogout = async (event) => {
    event.preventDefault();
    await localStorage.clear();
    navigate("/signup", { replace: true });
  }

  const configureHeader = (nheader) => {
    setHeader(nheader);
  }
  return (
    <Layout {...props} 
    header={header} 
    show={show} 
    handleShow={handleShow} 
    handleClose={handleClose} 
    handleLogin={handleLogin} 
    handleChange={handleChange} 
    handleLogout={handleLogout} 
    heading={"Login"} 
    message={"asdfsadf"} 
    toastShow={true}
    showCityModal={showCityModal}
    handleShowCityModal={handleShowCityModal}
    handleCloseCityModal={handleCloseCityModal}
    >
        <Routes>
          <Route path="/" element={<Home {...props} configureHeader={configureHeader}></Home>}>
          </Route>
          <Route path='/profile' element={<Profile {...props} configureHeader={configureHeader}></Profile>}>
            
          </Route>
          <Route path='/profile/qualification' element={<Qualifications  {...props}></Qualifications>}>

          </Route>
          <Route path='/profile/courses' element={<Courses  {...props}></Courses>}>

          </Route>
          <Route path='/search/:term/:location' element={<SearchResult show={show} {...props}  configureHeader={configureHeader}></SearchResult>}>
            
          </Route>
          <Route path='/search/:term' element={<SearchResult show={show} {...props}  configureHeader={configureHeader}></SearchResult>}>
            
          </Route>
          <Route path='/tutor/:qrystr' element={<Detail show={show} handleShow={handleShow} handleClose={handleClose} handleLogin={handleLogin} handleChange={handleChange} {...props} configureHeader={configureHeader}></Detail>}>
            
          </Route>
          <Route path='/signup' element={<SignUp show={show} handleShow={handleShow} handleClose={handleClose} handleLogin={handleLogin} handleChange={handleChange} {...props} configureHeader={configureHeader}></SignUp>}>
            
          </Route>
        </Routes>
    </Layout>

  )
}

const mapStateToProps = state => {
  return {
    commonData: state.commonReducer.data ? state.commonReducer.data : [],
    result: state.userReducer.data ? state.userReducer.data : [],
    loginResponse: state.userReducer.loginResponse ? state.userReducer.loginResponse : {},
    portalNavs: state.commonReducer.portalNavs ? state.commonReducer.portalNavs : {}
  }
}

export default connect(mapStateToProps, { getCommon, loginUser, getPortalNavigation })(App);