import axios from "axios";
import { ADD_USER, BASE_URL, GET_USER, LOGIN_USER, UPDATE_PROFILE, UPDATE_QUALIFICATION, GET_USER_DETAIL, UPDATE_USER_COURSES } from "../action/types"
import { getAuthToken, saveAuthToken } from "../Services/util";

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export const addUser = (values, history) => async dispatch => {
    const headers = {
        'Content-Type': 'application/json',
      }
    await axios.post(BASE_URL +'api/admin/user/save', values, {headers: headers})
    .then((result) => {
        saveAuthToken(result.headers);
        dispatch({ type: ADD_USER, payload: result.data});
    })
    .catch((err) => {
        dispatch({ type: ADD_USER, payload: null});
    });
    
}

export const getUser = (values, history) => async dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getAuthToken()
    }

    axios.post(BASE_URL + 'api/admin/user/get', {}, {headers: headers})
    .then((result) => {
        dispatch({ type: GET_USER, payload: JSON.stringify(result.data)});
    })
}

export const getUserDetail = (values, history) => async dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getAuthToken()
    }

    axios.post(BASE_URL + 'api/admin/user/detail', values   , {headers: headers})
    .then((result) => {
        dispatch({ type: GET_USER_DETAIL, payload: JSON.stringify(result.data)});
    })
}

export const loginUser = (values, history) => async dispatch => {
    const headers = {
        'Content-Type': 'application/json'
    }

    await axios.post(BASE_URL + 'api/admin/user/login', values, {headers: headers})
    .then((result) => {
        saveAuthToken(result.headers);
        dispatch({ type: LOGIN_USER, payload: result.data});
    })
}

export const updateProfile = (values, history) => async dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getAuthToken()
      }
    axios.post(BASE_URL +'api/admin/user/update/profile', values, {headers: headers})
    .then((result) => {
        dispatch({ type: UPDATE_PROFILE, payload: result.data});
    })
    .catch((err) => {
        dispatch({ type: UPDATE_PROFILE, payload: null});
    });
    
}

export const updateQualificataion = (values, history) => async dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getAuthToken()
      }
    axios.post(BASE_URL +'api/admin/user/update/qualification', values, {headers: headers})
    .then((result) => {
        dispatch({ type: UPDATE_QUALIFICATION, payload: result.data});
    })
    .catch((err) => {
        dispatch({ type: UPDATE_QUALIFICATION, payload: null});
    });
    
}

export const updateUserCourses = (values, history) => async dispatch => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+getAuthToken()
      }
    axios.post(BASE_URL +'api/admin/user/update/courses', values, {headers: headers})
    .then((result) => {
        dispatch({ type: UPDATE_USER_COURSES, payload: result.data});
    })
    .catch((err) => {
        dispatch({ type: UPDATE_USER_COURSES, payload: null});
    });
    
}