import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserDetail } from "../../model/user";
import UserCourses from "../Account/Course/userCourses";
import UserQualifications from "../Account/Qualification/userQualifications";
import EnquiryModal from "../Enquiry/enquiryModal";
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// import MsgToast from "../Popup/msgToast";

const Detail = (props) => {
	const [show, setShow] = useState(false);
	const [userDetail, setUserDetail] = useState({user_id: props.user});
	const [profileImage, setProfileImage] = useState('');
	const [skills, setSkills] = useState([]);
	const query = useParams();

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		props.getUserDetail({
			query: query['qrystr']
		});
	}, [])

	useEffect(() => {
		setSkills([]);
		props.userDetail && props.userDetail.data && setUserDetail(props.userDetail.data);
		props.userDetail?.profileImage != undefined && setProfileImage(props.userDetail.profileImage);
		props?.userDetail?.data?.userCourses?.map((item =>
			setSkills(values => ([...values, item.course]))
		));
	}, [props.userDetail])
	return (
		<section className="middle">
			<div className="container">
				<div className="row align-items-start justify-content-between">

					<div className="col-12 col-md-12 col-lg-4 col-xl-4 text-center miliods">
						<div className="d-block border rounded mfliud-bot mb-4">
							<div className="cdt_author px-2 pt-5 pb-4">
								<div className="dash_auth_thumb circle p-1 border d-inline-flex mx-auto mb-2">
									<img src={ profileImage } className="img-fluid circle" width="100" alt="" />
								</div>
								<div className="dash_caption mb-3">
									<h4 className="fs-lg ft-medium mb-0 lh-1">{userDetail.first_name + (userDetail.last_name && ' ' + userDetail.last_name)}</h4>
									<p className="m-0 p-0">Web Designer</p>
									<span className="text-muted smalls"><i className="lni lni-map-marker mr-1"></i>{userDetail.address + (userDetail.city && ', ' + userDetail.city) + (userDetail.state && ', ' + userDetail.state)}</span>
								</div>
								<div className="jb-list-01-title px-2">
									{
										skills?.map(skill => <span className="mr-2 mb-2 d-inline-flex px-2 py-1 rounded theme-cl theme-bg-light">{skill}</span>)
									}
								</div>
							</div>

							<div className="cdt_caps">
								<div className="job_grid_footer pb-3 px-3 d-flex align-items-center justify-content-between">
									<div className="df-1 text-muted"><i className="lni lni-briefcase mr-1"></i>Full Time</div>
									<div className="df-1 text-muted"><i className="lni lni-laptop-phone mr-1"></i>Web Designer</div>
								</div>
								<div className="job_grid_footer px-3 d-flex align-items-center justify-content-between">
									<div className="df-1 text-muted"><i className="lni lni-envelope mr-1"></i>{userDetail?.email}</div>
									<div className="df-1 text-muted"><i className="lni lni-graduation mr-1"></i>{userDetail?.experience} Year Exp.</div>
								</div>
							</div>

							<div className="cdt_caps py-5 px-3">
								<Nav.Link href="#" className="btn btn-md theme-bg text-light rounded full-width" onClick={handleShow} >Enquiry</Nav.Link>
							</div>

						</div>
					</div>

					<div className="col-12 col-md-12 col-lg-8 col-xl-8">

						{/* <!-- row --> */}
						<div className="row align-items-start">

							{/* <!-- About --> */}
							<div className="abt-cdt d-block full-width mb-4">
								<h4 className="ft-medium mb-1 fs-md">About {userDetail.first_name + (userDetail.last_name && ' ' + userDetail.last_name)}</h4>
								{HTMLReactParser('' + userDetail.about_me)}
							</div>
							{userDetail?.userCourses && <UserCourses courses={userDetail.userCourses}></UserCourses>}
							{/* <!-- Experience --> */}
							{/* <!-- Qualification --> */}
							{
								userDetail.qualifications && <UserQualifications qualifications={userDetail.qualifications}></UserQualifications>
							}



							{/* <!-- Skills --> */}
							<div className="abt-cdt d-block full-width">
								<h4 className="ft-medium mb-1 fs-md">Skills</h4>
								<ul className="p-0 skills_tag text-left">
									<li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
									<li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
									<li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
									<li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
									<li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
									<li><span className="px-2 py-1 medium skill-bg rounded text-dark">MS SQL</span></li>
									<li><span className="px-2 py-1 medium skill-bg rounded text-dark">SQL Development</span></li>
									<li><span className="px-2 py-1 medium skill-bg rounded text-dark">Dynamod</span></li>
									<li><span className="px-2 py-1 medium skill-bg rounded text-dark">Database</span></li>
								</ul>
							</div>

						</div>
						{/* <!-- row --> */}

					</div>

				</div>
			</div>
			<EnquiryModal
				{...props}
				handleClose={handleClose}
				show={show}
				courses={userDetail?.userCourses}
			></EnquiryModal>
		</section>
	)
}

const mapStateToProps = state => {
	return {
		userDetail: (state.userReducer.userDetail && state.userReducer.userDetail.data !== undefined) ? state.userReducer.userDetail : [],
	}
}
export default connect(mapStateToProps, { getUserDetail })(Detail);