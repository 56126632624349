import { NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";

const SearchList = (props) => {
	let courses = props.info.userCourses && props.info.userCourses.map((item) => {
		/* if(!courses.includes(item.course)) {
			console.log(courses);
			return item.course;
		} */
		return item.course;
	});
	let teaching_mode = props.info.userCourses && props.info.userCourses.map(item => item.teaching_mode.charAt(0).toUpperCase() + item.teaching_mode.slice(1));
	let teaching_mode_unique = teaching_mode && teaching_mode.filter((c, index) => {
		return teaching_mode.indexOf(c) === index;
	})
	
	let url = [props.info.first_name, props.info.last_name, props.info.id].join("-");
	let image_120 = props.info?.image_120_120 + '/' + props.info?.asset?.asset_name;
	
	//https://via.placeholder.com/120x120
	return (
		<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div className="jbr-wrap text-left border rounded">
				<div className="cats-box mlb-res rounded bg-white d-flex align-items-center justify-content-between px-3 py-3">
					<div className="cats-box rounded bg-white d-flex align-items-center">
						<div className="text-center"><Link to={encodeURI("/tutor/" + url)}><img src={props.info?.asset?.asset_name !== undefined && image_120 || "https://via.placeholder.com/120x120"} className="img-fluid" width="120" alt="" /></Link></div>
						<div className="cats-box-caption px-2">
							<h4 className="fs-md mb-0 ft-medium">{props.info.first_name +' '+props.info.last_name}</h4>
							<div className="d-block mb-2 position-relative">
								<span className="text-muted medium"><i className="lni lni-map-marker mr-1"></i>{props.info.city+''+(props.info.state && `, ${props.info.state}`)}</span>
								{
									teaching_mode && <span className="muted medium ml-2 theme-cl"><i className="lni lni-display-alt mr-1"></i>{teaching_mode_unique.join(", ")}</span>
								}
								
								{
									props.info.experience && <span className="muted medium ml-2 text-warning"><i className="lni lni-briefcase mr-1"></i>{props.info.experience} Yrs. of Experience</span>
								}
								{
									props.info.userCourses &&
									<p><i className="lni lni-graduation mr-1"></i><strong>Classes:</strong> {courses.join(", ")}</p>
								}
								
							</div>
						</div>
					</div>
					{/* <div className="text-center mlb-last"><a href="job-detail.html" className="btn theme-bg text-white ft-medium apply-btn fs-sm rounded">Contact<i className="lni lni-arrow-right-circle ml-1"></i></a></div> */}
				</div>
			</div>
		</div>
	);
}

export default SearchList;