const PopularJobs = () => {
    return(
        <section className="middle">
            <div className="container">
            
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="sec_title position-relative text-center mb-5">
                            <h6 className="text-muted mb-0">Trending Jobs</h6>
                            <h2 className="ft-bold">All Popular Listed jobs</h2>
                        </div>
                    </div>
                </div>
                
                
                <div className="row align-items-center">
                
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="position-absolute ab-right"><span className="medium theme-cl theme-bg-light px-2 py-1 rounded">Full Time</span></div>
                            <div className="job_grid_thumb mb-2 pt-5 px-3">
                                <a href="job-detail.html" className="d-block text-center m-auto"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="job-detail.html" className="text-dark fs-md">UI/UX Web Designer</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="position-absolute ab-right"><span className="medium bg-light-warning text-warning px-2 py-1 rounded">Part Time</span></div>
                            <div className="job_grid_thumb mb-2 pt-5 px-3">
                                <a href="job-detail.html" className="d-block text-center m-auto"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="job-detail.html" className="text-dark fs-md">UI/UX Web Designer</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="position-absolute ab-right"><span className="medium bg-light-purple text-purple px-2 py-1 rounded">Contract</span></div>
                            <div className="job_grid_thumb mb-2 pt-5 px-3">
                                <a href="job-detail.html" className="d-block text-center m-auto"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="job-detail.html" className="text-dark fs-md">UI/UX Web Designer</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="position-absolute ab-right"><span className="medium bg-light-danger text-danger px-2 py-1 rounded">Enternship</span></div>
                            <div className="job_grid_thumb mb-2 pt-5 px-3">
                                <a href="job-detail.html" className="d-block text-center m-auto"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="job-detail.html" className="text-dark fs-md">UI/UX Web Designer</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="position-absolute ab-right"><span className="medium bg-light-purple text-purple px-2 py-1 rounded">Contract</span></div>
                            <div className="job_grid_thumb mb-2 pt-5 px-3">
                                <a href="job-detail.html" className="d-block text-center m-auto"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="job-detail.html" className="text-dark fs-md">UI/UX Web Designer</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="position-absolute ab-right"><span className="medium theme-cl theme-bg-light px-2 py-1 rounded">Full Time</span></div>
                            <div className="job_grid_thumb mb-2 pt-5 px-3">
                                <a href="job-detail.html" className="d-block text-center m-auto"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="job-detail.html" className="text-dark fs-md">UI/UX Web Designer</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="position-absolute ab-right"><span className="medium bg-light-danger text-danger px-2 py-1 rounded">Enternship</span></div>
                            <div className="job_grid_thumb mb-2 pt-5 px-3">
                                <a href="job-detail.html" className="d-block text-center m-auto"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="job-detail.html" className="text-dark fs-md">UI/UX Web Designer</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="job_grid border rounded ">
                            <div className="position-absolute ab-left"><button type="button" className="p-3 border circle d-flex align-items-center justify-content-center bg-white text-gray"><i className="lni lni-heart-filled position-absolute snackbar-wishlist"></i></button></div>
                            <div className="position-absolute ab-right"><span className="medium bg-light-warning text-warning px-2 py-1 rounded">Part Time</span></div>
                            <div className="job_grid_thumb mb-2 pt-5 px-3">
                                <a href="job-detail.html" className="d-block text-center m-auto"><img src="https://via.placeholder.com/120x120" className="img-fluid" width="70" alt="" /></a>
                            </div>
                            <div className="job_grid_caption text-center pb-3 px-3">
                                <h4 className="mb-0 ft-medium medium"><a href="job-detail.html" className="text-dark fs-md">UI/UX Web Designer</a></h4>
                                <div className="jbl_location"><i className="lni lni-map-marker mr-1"></i><span>San Francisco</span></div>
                            </div>
                            <div className="job_grid_footer pb-4 px-3">
                                <ul className="p-0 skills_tag text-center">
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Joomla</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">WordPress</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">Javascript</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">PHP</span></li>
                                    <li><span className="px-2 py-1 medium skill-bg rounded text-dark">HTML5</span></li>
                                    <li><span className="px-2 py-1 medium theme-bg rounded text-light">+3 More</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="position-relative text-center">
                            <a href="job-search-v1.html" className="btn btn-md theme-bg rounded text-light hover-theme">Explore More Jobs<i className="lni lni-arrow-right-circle ml-2"></i></a>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default PopularJobs;