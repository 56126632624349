import { ADD_USER_ENQUIRY } from "../action/types";

export default function (state = {}, action) {
    switch (action.type) {
        case ADD_USER_ENQUIRY:
            return { ...state, data: action.payload }
            break;
        default:
            return state;
    }
}
