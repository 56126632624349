import axios from "axios";
import { GET_COMMON, BASE_URL, GET_LOC_BY_PIN_CITY, GET_PORTAL_NAVS, GET_LOC_BY_PIN_OR_CITY } from "../action/types"
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { NOTIFY_ACTION_SUCCESS, NOTIFY_ACTION_ERROR } from "../config/constant";

export const getCommon = (values, history) => async dispatch => {
    const res = await axios.get(BASE_URL+'api/admin/common/list');
    dispatch({ type: GET_COMMON, payload: JSON.stringify(res)});
}

export const getLocationByPinOrCity = (values, history) => async dispatch => {
    const res = await axios.get(BASE_URL+`api/admin/pincode/get/${values}`);
    dispatch({ type: GET_LOC_BY_PIN_CITY, payload: JSON.stringify(res)});
}

export const getPortalNavigation = (values, history) => async dispatch => {
    const res = await axios.get(BASE_URL + `api/admin/portal/navs`);
    dispatch({type: GET_PORTAL_NAVS, payload: JSON.stringify(res)});
}

export const getCityList = (values, history) => async dispatch => {
    const res = await axios.get(BASE_URL+`api/admin/search/city/${values}`);
    dispatch({ type: GET_LOC_BY_PIN_OR_CITY, payload: res});
}

export const notify = (notifyAction, content) => {
    if (notifyAction === NOTIFY_ACTION_SUCCESS) {
        toast.success(content ? content : "Success", {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    if (notifyAction === NOTIFY_ACTION_ERROR) {
        toast.error(content ? content : "Failed", {
            position: toast.POSITION.TOP_RIGHT
        });
    }
};