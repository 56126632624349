import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { updateUserCourses } from "../../../model/user";
import DashboardNavigation from "../../Include/dashboardnavigation";
import Course from "./course";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { NOTIFY_ACTION_ERROR, NOTIFY_ACTION_SUCCESS } from "../../../config/constant";
import { UPDATE_USER_COURSES } from "../../../action/types";


const Courses = (props) => {
    const dispatch = useDispatch();
    let [coursesMD, setCoursesMD] = useState([]);
	let [teachingModeMD, setTeachingModeMD] = useState([]);
	let [feeTypeMD, setFeeTypeMD] = useState([]);

    const [alertMsg, setAlertMsg] = useState({
        key: '',
        variant: '',
        message: ''
    });
    const courseProps = { 
        _id: new Date().getTime() + Math.random(), 
        course_id: null, 
        teaching_mode: null, 
        fee_type: null, 
        fee: '', 
        about_course: '', 
        experience: 0 
    }
    const [courseArr, setCourseArr] = useState([courseProps]);
    const notify = (notifyAction, content) => {
        if (notifyAction === NOTIFY_ACTION_SUCCESS) {
            toast.success(content ? content : "Success", {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        if (notifyAction === NOTIFY_ACTION_ERROR) {
            toast.error(content ? content : "Failed", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        dispatch({ type: UPDATE_USER_COURSES, payload: null});
    };


    const handleAddMoreCourse = () => {
        const newCoursProps = {...courseProps};
        newCoursProps._id = (new Date().getTime() + Math.random()).toString();
        setCourseArr(values => ([...courseArr, newCoursProps]));
    }

    const handleRemoveCourse = (_id) => {
        const newCrsArr = [...courseArr];
        const newCourseArr = newCrsArr.filter((item) => {
            return _id!==item._id
        });
        setCourseArr(newCourseArr);
    }

    const handleUpdateCourses = async (event) => {
        setCourseArr(courseArr);
        await props.updateUserCourses({courses: courseArr})
        
    }

    const handleInputChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        const NewCourseArr = [...courseArr];
        NewCourseArr[index][name] = value;
        setCourseArr(NewCourseArr);
    }

    useEffect(() => {
        props.userQualData && setAlertMsg({key: 'info', variant: 'info', message: props.userQualData.message});
    },[props.userQualData]);

    useEffect(() => {
        let userQl = new Array();
        setCourseArr([]);
		props?.userProfileData?.data?.userCourses?.map((item) => {
            setCourseArr(values => ([...values, {
                _id: item.id,
                course_id: item.course_id, 
                teaching_mode: item.teaching_mode, 
                fee_type: item.fee_type, 
                fee: item.fee, 
                about_course: item.about_course, 
                experience: item.experience,
            }]));
        });
	}, [props.userProfileData]);

    useEffect(() => { 
		if (props.commonData.data) {
			let teachingModeData = props.commonData.data.masterData.data.filter(item => item.attribute_name === 'teaching_mode');
			setTeachingModeMD(teachingModeData);
			let feeTypeData = props.commonData.data.masterData.data.filter(item => item.attribute_name === 'fee_type');
			setFeeTypeMD(feeTypeData);
			setCoursesMD(props.commonData.data.courses.data);

		}
	}, [props.commonData]);

    useEffect(() => {
        if(props.userCoursesData?.code=="200") {
            notify(NOTIFY_ACTION_SUCCESS, props.userCoursesData?.message);
        }
        if(props.userCoursesData !== null && props.userCoursesData?.message) {
            notify(NOTIFY_ACTION_SUCCESS, props.userCoursesData?.message);
        }
    }, [props.userCoursesData]);
    return (
        <div className="dashboard-wrap bg-light">
            <ToastContainer />
            <DashboardNavigation></DashboardNavigation>

            <div className="dashboard-content">
                <div className="dashboard-tlbar d-block mb-5">
                    <div className="row">
                        <div className="colxl-12 col-lg-12 col-md-12">
                            <h1 className="ft-medium">My Profile</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item text-muted"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item text-muted"><a href="#">My Profile</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="theme-cl">Courses</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                {
                    alertMsg && alertMsg.message !== '' &&
                    <Alert key={"info"} variant={"info"}>
                        { alertMsg.message }
                    </Alert>
                }

                {
                    courseArr.map((item, index) => <Course
                    courses={coursesMD}
                    teachingMode={teachingModeMD}
                    feeType={feeTypeMD} 
                    handleInputChange={handleInputChange} 
                    id={item._id}
                    key={item._id} 
                    _index={index} 
                    offeredCourse={item}
                    addMore={handleAddMoreCourse} 
                    removeCourse={handleRemoveCourse}>
                    </Course>)
                }
                <div className="row">
                    <div className="col-xl-2 col-lg-2">
                        <div className="form-group">
                            <button type="button" className="btn btn-md ft-medium text-light rounded theme-bg" onClick={handleUpdateCourses}>Save</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

const mapStateToProps = state => {
	return {
        userProfileData: state.userReducer.userProfileData ? state.userReducer.userProfileData : [],
		userCoursesData: state?.userReducer?.userCoursesData ? state.userReducer.userCoursesData : []
	}
}
export default connect(mapStateToProps, { updateUserCourses })(Courses);