import { GET_COMMON, GET_LOC_BY_PIN_CITY, GET_PORTAL_NAVS, GET_LOC_BY_PIN_OR_CITY } from "../action/types";

export default function (state = {}, action) {
    switch (action.type) {
        case GET_COMMON:
            return { ...state, data: JSON.parse(action.payload) }
        case GET_LOC_BY_PIN_CITY:
            return { ...state, locdata: JSON.parse(action.payload) }
        case GET_LOC_BY_PIN_OR_CITY:
            return { ...state, citylist: action.payload }
        case GET_PORTAL_NAVS:
            return { ...state, portalNavs: JSON.parse(action.payload) }
        default:
            return state;
    }
}
