import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { updateQualificataion } from "../../../model/user";
import DashboardNavigation from "../../Include/dashboardnavigation";
import Qualification from "./qualification";


const Qualifications = (props) => {

    let [userQualifications, setUserQualifications] = useState([]);

    const [alertMsg, setAlertMsg] = useState({
        key: '',
        variant: '',
        message: ''
    });
    const qualificationProps = {
        _id: (new Date().getTime() + Math.random()).toString(),
        qualification: '',
        university: '',
        passing_year: '',
        marks_obtained: ''
    }
    const [qualificationArr, setQualificationArr] = useState([qualificationProps]);

    const handleAddMoreQualification = () => {
        const newQualProps = {...qualificationProps};
        newQualProps._id = (new Date().getTime() + Math.random()).toString();
        setQualificationArr(values => ([...qualificationArr, newQualProps]));
    }

    const handleRemoveQualification = (_id) => {
        const newQualArr = [...qualificationArr];
        console.log(newQualArr, qualificationArr);
        const newQualificationArr = newQualArr.filter((item) => {
            return _id!==item._id
        });
        setQualificationArr(newQualificationArr);
    }

    const handleUpdateQualification = async (event) => {
        await props.updateQualificataion({qualifications: qualificationArr})
        
    }

    const handleInputChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        const qualArr = [...qualificationArr];
        qualArr[index][name] = value;
        setQualificationArr(qualArr);
    }

    useEffect(() => {
        props.userQualData && setAlertMsg({key: 'info', variant: 'info', message: props.userQualData.message});
    },[props.userQualData]);

    useEffect(() => {
        let userQl = new Array();
        setQualificationArr([]);
		props.userProfileData.data !== undefined && props.userProfileData.data.userQualifications !== undefined && props.userProfileData.data.userQualifications.map((item) => {
            // userQl = [...userQl, {
            //     _id: item.id,
            //     qualification: item.qualification,
            //     university: item.university,
            //     passing_year: item.passing_year,
            //     marks_obtained: item.marks_obtained
            // }];
            setQualificationArr(values => ([...values, {
                _id: item.id,
                qualification: item.qualification,
                university: item.university,
                passing_year: item.passing_year,
                marks_obtained: item.marks_obtained,
                about_qualification: item.about_qualification
            }]));
        });
	}, [props.userProfileData]);

    return (
        <div className="dashboard-wrap bg-light">
            <DashboardNavigation></DashboardNavigation>

            <div className="dashboard-content">
                <div className="dashboard-tlbar d-block mb-5">
                    <div className="row">
                        <div className="colxl-12 col-lg-12 col-md-12">
                            <h1 className="ft-medium">My Profile</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item text-muted"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item text-muted"><a href="#">My Profile</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="theme-cl">Qualification</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                {
                    alertMsg && alertMsg.message !== '' &&
                    <Alert key={"info"} variant={"info"}>
                        { alertMsg.message }
                    </Alert>
                }

                {
                    qualificationArr.map((item, index) => <Qualification handleInputChange={handleInputChange} key={item._id} _index={index} qualInfo={item} addMore={handleAddMoreQualification} removeQualification={handleRemoveQualification}></Qualification>)
                }
                <div className="row">
                    <div className="col-xl-2 col-lg-2">
                        <div className="form-group">
                            <button type="button" className="btn btn-md ft-medium text-light rounded theme-bg" onClick={handleUpdateQualification}>Save</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

const mapStateToProps = state => {
	return {
        userProfileData: state.userReducer.userProfileData ? state.userReducer.userProfileData : [],
		userQualData: state.userReducer.userQualData ? state.userReducer.userQualData : [],
        // portalNavs: state.commonReducer.portalNavs ? state.commonReducer.portalNavs : {}
	}
}
export default connect(mapStateToProps, { updateQualificataion })(Qualifications);